<template>
  <div ng-if="asset" class="asset-transmitter d-flex flex-column flex-grow-1">
    <div class="row">
      <table class="table table-bordered col-md-6">
        <thead>
        <tr>
          <th colspan="2">{{$t('js.transmitter.table.device_status.name')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{$t('js.transmitter.table.device_status.op_mode')}}</td>
          <td class="text-center">
            <div v-if="raw_packet && raw_packet.dev_status" :style="styleText(raw_packet.dev_status.status.op_mode)" >
              {{raw_packet.dev_status.status.op_mode}}
            </div>
          </td>
        </tr>
        <tr>
          <td>Radio</td>
          <td class="text-center">
            <div v-if="raw_packet && raw_packet.dev_status" :style="styleText(!raw_packet.dev_status.status.int_radio)">
              {{ convertBooleanToOnOrOff(!raw_packet.dev_status.status.int_radio) }}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.device_status.fuel_gauge')}}</td>
          <td class="text-center">
            <div v-if="raw_packet && raw_packet.dev_status" :style="styleText(!raw_packet.dev_status.status.fuel_gauge)">
              {{ convertBooleanToOnOrOff(!raw_packet.dev_status.status.fuel_gauge) }}
            </div>
          </td>
        </tr>
        <tr>
          <td>Data flash</td>
          <td class="text-center">
            <div v-if="raw_packet && raw_packet.dev_status" :style="styleText(!raw_packet.dev_status.status.data_flash)">
              {{ convertBooleanToOnOrOff(!raw_packet.dev_status.status.data_flash) }}
            </div>
          </td>
        </tr>
        <tr>
          <td>GNSS</td>
          <td class="text-center">
            <div v-if="raw_packet && raw_packet.dev_status" :style="styleText(!raw_packet.dev_status.status.gnss)">
              {{ convertBooleanToOnOrOff(!raw_packet.dev_status.status.gnss) }}
            </div>
          </td>
        </tr>
        <tr>
          <td>GSM</td>
          <td class="text-center">
            <div v-if="raw_packet && raw_packet.dev_status" :style="styleText(!raw_packet.dev_status.status.gsm)">
              {{ convertBooleanToOnOrOff(!raw_packet.dev_status.status.gsm) }}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.device_status.accelerometer')}}</td>
          <td class="text-center">
            <div v-if="raw_packet && raw_packet.dev_status" :style="styleText(!raw_packet.dev_status.status.accelerometer)">
              {{ convertBooleanToOnOrOff(!raw_packet.dev_status.status.accelerometer) }}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.device_status.temperature_internal_sensor')}}</td>
          <td class="text-center">
            <div v-if="raw_packet && raw_packet.dev_status" :style="styleText(!raw_packet.dev_status.status.tint_sensor)">
              {{ convertBooleanToOnOrOff(!raw_packet.dev_status.status.tint_sensor) }}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.device_status.temperature_humidity_external_sensor')}}</td>
          <td class="text-center">
            <div v-if="raw_packet && raw_packet.dev_status" :style="styleText(!raw_packet.dev_status.status.t_rh_ext_sensor)">
              {{ convertBooleanToOnOrOff(!raw_packet.dev_status.status.t_rh_ext_sensor) }}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.device_status.temperature_humidity_internal_sensor')}}</td>
          <td class="text-center">
            <div v-if="raw_packet && raw_packet.dev_status" :style="styleText(!raw_packet.dev_status.status.t_rh_int_sensor)">
              {{ convertBooleanToOnOrOff(!raw_packet.dev_status.status.t_rh_int_sensor) }}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.device_status.pressure_sensor')}}</td>
          <td class="text-center">
            <div v-if="raw_packet && raw_packet.dev_status" :style="styleText(!raw_packet.dev_status.status.pressure_sensor)">
              {{ convertBooleanToOnOrOff(!raw_packet.dev_status.status.pressure_sensor) }}
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <table class="table table-bordered col-md-6">
        <thead>
        <tr>
          <th colspan="2">GNSS</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>GNSS TTFF</td>
          <td>
            <div v-if="raw_packet && raw_packet.debug_data && raw_packet.debug_data.dev_debug_data">
              {{raw_packet.debug_data.dev_debug_data.ttff}}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.gnss.latitude')}}</td>
          <td>
            <div v-if="raw_packet && raw_packet.loc_data!=null">
              {{raw_packet.loc_data.loc.lat}}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.gnss.longitude')}}</td>
          <td>
            <div v-if="raw_packet && raw_packet.loc_data">
              {{raw_packet.loc_data.loc.lng}}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.gnss.altitude')}}</td>
          <td>
            <div v-if="dev_location_measurement && dev_location_measurement.alt != null">
              {{dev_location_measurement.alt}}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.gnss.speed')}}</td>
          <td>
            <div v-if="dev_location_measurement && dev_location_measurement.speed != null">
              {{dev_location_measurement.speed.toFixed(6)}}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.gnss.sats_in_use')}}</td>
          <td>
            <div v-if="dev_location_measurement && dev_location_measurement.sats_in_use != null">
              {{dev_location_measurement.sats_in_use}}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.gnss.sats_in_view')}}</td>
          <td>
            <div v-if="dev_location_measurement && dev_location_measurement.sats_in_view != null">
              {{dev_location_measurement.sats_in_view}}
            </div>
          </td>
        </tr>
        <tr>
          <td>Fix stat</td>
          <td>
            <div v-if="dev_location_measurement && dev_location_measurement.fix_stat != null">
              {{dev_location_measurement.fix_stat}}
            </div>
          </td>
        </tr>
        <tr>
          <td>Fix mode</td>
          <td>
            <div v-if="dev_location_measurement && dev_location_measurement.fix_mode != null">
              {{dev_location_measurement.fix_mode}}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.gnss.gps_time')}}</td>
          <td>
            <div  v-if="raw_packet && raw_packet.loc_data">
              {{displayDate(raw_packet.loc_data.timestamp)}}
            </div>
          </td>
        </tr>
        <tr>
          <td>EPO expires</td>
          <td>
            <div v-if="dev_status_measurements && dev_status_measurements.epo_exp">
              {{displayDate(dev_status_measurements.epo_exp)}}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <table class="table table-bordered col-md-6">
        <thead>
        <tr>
          <th colspan="1" class="border-0">{{$t('js.transmitter.table.battery_status.name')}}</th>
          <div><button class="btn btn-danger float-right btn-sm mr-3" style="margin-top: 1px" @click="newBattery">
            {{$t('js.transmitter.table.battery_status.reset_battery')}}
          </button></div>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{$t('js.transmitter.table.battery_status.battery_level')}}</td>
          <td class="text-center">
            <div v-if="dev_status_measurements && dev_status_measurements.battery_charge_perc">
              {{dev_status_measurements.battery_charge_perc}}%
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.battery_status.voltage')}}</td>
          <td class="text-center">
            <div v-if="dev_status_measurements && dev_status_measurements.battery_voltage">
              {{(dev_status_measurements.battery_voltage/1000).toFixed(1)}}V
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.battery_status.battery_temperature')}}</td>
          <td class="text-center">
            <div v-if="dev_status_measurements && dev_status_measurements.battery_temp">
              {{dev_status_measurements.battery_temp.toFixed(2)}}°C
            </div>
          </td>
        </tr>
        <tr>
          <td>Crit</td>
          <td class="text-center">
            <div v-if="dev_status_measurements && dev_status_measurements.battery_crit">
              {{dev_status_measurements.battery_crit}}
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <table class="table table-bordered col-md-6">
        <thead>
        <tr>
          <th colspan="2">Selftest stats</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Total selftest</td>
          <td>
            <div v-if="raw_packet && raw_packet.debug_data && raw_packet.debug_data.dev_debug_data.selftest_stats">
              {{raw_packet.debug_data.dev_debug_data.selftest_stats.tests_total}}
            </div>
          </td>
        </tr>
        <tr>
          <td>Implemented</td>
          <td>
            <div v-if="raw_packet && raw_packet.debug_data && raw_packet.debug_data.dev_debug_data.selftest_stats">
              {{raw_packet.debug_data.dev_debug_data.selftest_stats.tests_implemented}}
            </div>
          </td>
        </tr>
        <tr>
          <td>Passed</td>
          <td>
            <div v-if="raw_packet && raw_packet.debug_data && raw_packet.debug_data.dev_debug_data.selftest_stats">
              {{raw_packet.debug_data.dev_debug_data.selftest_stats.tests_passed}}
            </div>
          </td>
        </tr>
        <tr>
          <td>Failed</td>
          <td>
            <div v-if="raw_packet && raw_packet.debug_data && raw_packet.debug_data.dev_debug_data.selftest_stats">
              {{raw_packet.debug_data.dev_debug_data.selftest_stats.tests_failed}}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <table class="table table-bordered col-md-6">
        <thead>
        <tr>
          <th colspan="2">{{$t('js.transmitter.table.other_data.name')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Min ever free heap</td>
          <td>
            <div v-if="raw_packet && raw_packet.debug_data">
              {{raw_packet.debug_data.dev_debug_data.min_ever_free_heap}}
            </div>
          </td>
        </tr>
        <tr>
          <td>GSM RSSI</td>
          <td>
            <div v-if="dev_status_measurements && dev_status_measurements.gsm_rssi">
              {{dev_status_measurements.gsm_rssi}}
            </div>
          </td>
        </tr>
        <tr>
          <td>{{$t('js.transmitter.table.other_data.msg_timestamp')}}</td>
          <td>
            <div v-if="dev_status_measurements && dev_status_measurements.time">
              {{displayDate(dev_status_measurements.time)}}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import AxiosService from "../services/axios-service";
import Utils from "../services/utils";
import errorHandlingMixin from "./mixins/error-handling-mixin";
import {TASK_TYPES} from "../commons/constants";

export default {
  name: "transmitter-configuration-table",
  mixins: [errorHandlingMixin],
  props: {
    dev_location_measurement: {
      type: Object
    },
    dev_status_measurements: {
      type: Object
    },
    raw_packet: {
      type: Object
    },
  },
  computed: {
    styleText() {
      return (val) => {
        if(val==="ON" || val==="NORMAL" || val === true)
          return "color: green"
        else
          return "color: red"
      }
    },
    displayDate() {
      return (date) => {
        return moment(String(date)).format('YYYY-MM-DD HH:mm')
      }
    },
    convertBooleanToOnOrOff() {
      return (val) => {
        if (val === false)
          return 'OFF'
        else if (val === true)
          return 'ON'
      }
    },
  },
  methods: {
    async newBattery() {
      try {
        let axios = AxiosService.instance();
        const newTask = {
          task_type: TASK_TYPES.reset_battery,
        };
        const res = await axios.post(Utils.getPath() + '/transmitter_tasks', newTask);
        Turbolinks.visit(res.data.location);
      } catch (err) {
        this.handleAxiosErrors(err);
      }
    }
  }
}
</script>

<style scoped>

</style>