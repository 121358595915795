<template>
    <div>
        <div :class="this.classes" class="leaflet-touch">
            <div id="mapid" ref="map">
                <zone-description :zones="zones" :clicked-zone-id="clickedZoneId" :is-admin="isAdmin"></zone-description>
            </div>
            <div v-if="isAdmin">
                <div v-if="!newZone && !zoneId">
                    <button v-on:click="(e) => this.handleNewZoneClick(e)" class="ZonesMap-button btn btn-success btn-sm">{{$t('js.zones_map.add')}}</button>
                </div>
                <div v-else-if="newZone">
                    <button :disabled="editing" v-on:click="(e) => this.setEditing(e)" :class="this.buttonClassName">{{$t('js.zones_map.draw')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { createMap, createZone, createWagon} from "../mapUtils/map-components";
import ZoneDescription from "./zone-description";
import L from 'leaflet';
const encode = require('geojson-polyline').encode;

export default {
    components: {
        ZoneDescription, L
    },
    props: {
        zones: Array,
        lng: {
            type: Number,
            default: 19.480556,
        },
        lat: {
            type: Number,
            default: 52.06916,
        },
        level: {
            type: Number,
            default: 13,
        },
        zoneId: Number,
        newZoneUrl: {
            type: String,
            default: '/admin/zones/new',
        },
        newZone: Boolean,
        customPosition: String,
        isAdmin: Boolean,
        osmUrl: String,
        ormUrl: String,
    },
    data () {
        return {
            clickedZoneId: null,
            editing: null,
        }
    },
    created() {
        this.map = null;
        this.newPolygon = null;
        this.classes = this.isAdmin && (this.newZone || !this.zoneId) ? 'ZonesMap ZonesMap--withButton' : 'ZonesMap';
        this.buttonClassName = this.editing ? 'ZonesMap-button btn btn-sm btn-disabled' : 'ZonesMap-button btn btn-success btn-sm';
    },
    mounted() {
        const map = createMap(this.lat,this.lng,this.level,this.osmUrl, this.ormUrl, () => this.handleMapPreclick());
        this.map = map;
        const elementsArray = [];
        let selectedZone;

        this.zones.forEach(zone => {
            const leafletZone = createZone(zone, () => this.handleZoneClick(zone.id));
            elementsArray.push(leafletZone);
            if (zone.id === this.zoneId) {
                selectedZone = leafletZone;
            }
        });

        const elementsGroup = L.featureGroup(elementsArray);
        elementsGroup.addTo(map);

        if (selectedZone) {
            selectedZone.enableEdit();
        } else if (!this.customPosition) {
            const bounds = elementsGroup.getBounds();
            if (bounds.isValid()){
                map.fitBounds(bounds);
            }
        }

        map.on('editable:vertex:dragend', (e) => this.handleZoneSaving(e));
        map.on('editable:vertex:deleted', (e) => this.handleZoneSaving(e));
        map.on('editable:middlemarker:mousedown', (e) => this.handleZoneSaving(e));

    },
    methods: {
        handleMapPreclick() {
            if (this.clickedZoneId) {
                this.clickedZoneId=null;
            }
        },
        handleZoneClick(id) {
            this.clickedZoneId = id;
        },
        handleZoneSaving(event) {
            const latlngsArray = event.layer.getLatLngs();
            const zone = latlngsArray.reduce((acc, val) => {
                return acc.concat(val.map(latlng => ({
                    lng: latlng.lng,
                    lat: latlng.lat,
                })));
            }, []);

            const arrayLonLat = new Array(zone.length);
            for(let index=0;index<zone.length;index++){
                arrayLonLat[index]=new Array(2);
                arrayLonLat[index][0]=zone[index]['lng'];
                arrayLonLat[index][1]=zone[index]['lat'];
            }
            const polygon2 = {
                type: 'Polygon',
                coordinates: [arrayLonLat]
            };
            const encoded = encode(polygon2);
            const encodedcoordinates = encoded['coordinates'];
            document.getElementById('zone_geo_coords').value = encodedcoordinates[0];
        },
        handleNewZoneClick() {
            const newZoneUrl = this.newZoneUrl;
            const latlng = this.map.getCenter();
            location.href = `${newZoneUrl}?lat=${latlng.lat}&lng=${latlng.lng}`;
        },
        setEditing() {
            if (!this.editing) {
                this.editing = true;
                this.buttonClassName = 'ZonesMap-button btn btn-sm btn-disabled';
                this.newPolygon = this.map.editTools.startPolygon();
                this.newPolygon.on('editable:drawing:clicked', (e) => this.handleNewZoneEdit(e));
                this.newPolygon.on('editable:vertex:dragend', (e) => this.handleNewZoneEdit(e));
                this.newPolygon.on('editable:vertex:deleted', (e) => this.handleNewZoneEdit(e));
                this.newPolygon.on('editable:middlemarker:mousedown', (e) => this.handleNewZoneEdit(e));
            }
        },
        handleNewZoneEdit(event) {
            window.e = event;
            const vertexCount = event.layer.getLatLngs()[0].length;
            if (vertexCount > 2) {
                this.map.editTools.commitDrawing();
                this.handleZoneSaving(event);
            }
        }
    }
}
</script>