export default {
    methods: {
        formatDate(date) {
            if (date) {
                let config_date = new Date(date);
                return config_date.getFullYear() + "-" + (('0' + (config_date.getMonth() + 1)).slice(-2)) +
                    "-" + ('0' + config_date.getDate()).slice(-2) + " " + ('0' + config_date.getHours()).slice(-2) + ":" + ('0' + config_date.getMinutes()).slice(-2) + ":" + ('0' + config_date.getSeconds()).slice(-2);
            }
            return null
        },
    }
}