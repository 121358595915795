<template>
    <div class="mt-2">
        <h3 class="mb-2">{{$t('js.transmitter.firmware.name')}}</h3>
        <div class="custom-file">
            <input type="file" class="custom-file-input" @change="prepareFile" @dragover="dragging=true" @drop="dragging=false" @dragleave="dragging=false">
            <label class="custom-file-label" :data-browse="$t('js.transmitter.browse')">{{$t('js.transmitter.choose_file')}}</label>
        </div>
        <div v-if="loading">
            <div class="loader mt-3"></div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">{{$t('js.transmitter.firmware.file_name')}}</th>
              <th scope="col">{{$t('js.transmitter.firmware.date')}}</th>
              <th scope="col">{{$t('js.transmitter.firmware.user')}}</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(firmware,index) in firmwares">
              <td><input type="radio" name="selectedFirmware" v-model="radio" :value="index" @click="updateFirmware(firmware.fileName)"></td>
              <td>{{firmware.fileName}}</td>
              <td>{{firmware.date}}</td>
              <td>{{firmware.user}}</td>
              <td>
                <button class="btn btn-danger float-right btn-sm" @click="removeFirmware(index,firmware.fileName)">
                  <span class="fas fa-times fa-fw" aria-hidden="true"/>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

    </div>
</template>

<script>
    import AxiosService from "../services/axios-service";

    export default {
        name: "firmware-upload",
        data: function () {
            return {
                firmwares: [],
                radio: null,
                loading: false
            }
        },
        mounted() {
            this.getObjects();
        },
        methods: {
            convertDate(str) {
                var date = new Date(str),
                    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                    day = ("0" + date.getDate()).slice(-2);
                return [date.getFullYear(), mnth, day].join("-");
            },
            updateFirmware(version) {
                this.$emit('prepareUpdateInfo', {version: version})
            },
            async removeFirmware(id,name) {
                if (!confirm(this.$t('js.transmitter.firmware.remove.question'))) return;
                let axios = AxiosService.instance();
                await axios.delete(`/admin/firmware/delete`,{data: {fileName: name }});
                this.deleteObjectFromList(id);
                this.$emit('prepareUpdateInfo', {version: null})
            },
            prepareFile(event) {
                let selectedFile = event.target.files[0];
                if(selectedFile){
                    this.uploadFile(selectedFile,selectedFile.name,this.convertDate(selectedFile.lastModified),selectedFile.type);
                }
            },
            async uploadFile(selectedFile,name,date,type){
                this.loading = true;
                var formData = new FormData();
                formData.append("file", selectedFile);
                let axios = AxiosService.instance();
                await axios.post('/admin/firmware/', formData, {
                    headers: {
                        fileName: name,
                        fileDate: date,
                        fileType: type,
                    }
                });
                this.clearFirmwaresList();
                await this.getObjects();
            },
            async getObjects() {
                this.loading = true;
                let axios = AxiosService.instance();
                await axios.get(`/admin/firmware/`).then( obj => {
                    let responseJSON = JSON.parse(obj['request']['response']);
                    this.firmwares = responseJSON["data"];
                });
                this.$emit('prepareUpdateInfo', {version: null});
                this.radio = false;
                this.loading = false;
            },
            deleteObjectFromList(index){
                this.radio = false;
                this.firmwares.splice(index,1);
            },
            clearFirmwaresList() {
                for (let i = this.firmwares.length; i > 0 ; i--) {
                    this.firmwares.pop(i);
                }
            }
        }
    }
</script>

<style lang="scss">
    .loader {
        color:grey;
        height:20px;
        left:50%;
        overflow-x:hidden;
        position:relative;
        top:50%;
        transform:translate(-50%,-50%);
        width:30vw;
        &:before {
            animation:left-to-right 3s alternate infinite linear;
            background-image:linear-gradient(to right, transparent, #77694b, transparent);
            content:"";
            height:20px;
            left:0;
            overflow:hidden;
            position:absolute;
            right:0;
            top:0;
        }
        &:after {
            background-image:repeating-linear-gradient(
                90deg,
                 white,
                 white 1vw,
                 transparent 1vw,
                 transparent 10vw
            );
            content:"";
            height:20px;
            left:0;
            overflow:hidden;
            position:absolute;
            right:0;
            top:0;
        }
    }

    @keyframes left-to-right {
        0% {
            transform:translateX(-100%);
        }
        100% {
            transform:translateX(100%);
        }
    }
</style>