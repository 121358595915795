import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import VueI18n from 'vue-i18n'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import BootstrapAlerts  from '../src/vue_components/bootstrap_alert'

import VueCharts from "vue-chartjs";
window.Vue = Vue;

Vue.use(TurbolinksAdapter);
Vue.use(VueI18n);
Vue.use(BootstrapAlerts);
Vue.use(VueCharts)

Vue.component('v-select', vSelect);

const i18n = new VueI18n({
    locale: 'en'
});

// register vue components from vue_components directory
const files = require.context('../src/vue_components', true, /\.vue$/i);
files.keys().map(function (key) {
    const component = key.split('/').pop().split('.')[0];
    Vue.component(component, files(key).default)
})

// mount vue on each tag with `data-vue-wrapper` attribute
const mountVueComponents = function () {
    i18n.locale = document.body.getAttribute("data-locale");
    if (!i18n.locale) {
        console.warn('Missing locale!')
    }
    const messages = window.LocaleData.translations[i18n.locale];
    i18n.setLocaleMessage(i18n.locale, messages);

    const elements = document.querySelectorAll('[data-vue-wrapper]');
    for (let i = 0; i < elements.length; i++){
        const app = new Vue({
            el: elements[i],
            i18n
        })
    }
}
//this set the trigger for page changes
document.addEventListener('turbolinks:load', mountVueComponents);