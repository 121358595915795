import L from "leaflet";
import {GeoSearchControl, OpenStreetMapProvider} from "leaflet-geosearch";
const decode = require('geojson-polyline').decode;

import './TileLayer.Greyscale'
import './Leaflet.Editable'
import './L.Control.BoxZoom'
import './L.Control.Zoomslider'

const DEFAULT_LAT = 50.067165;
const DEFAULT_LONG = 19.947503;

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
const aquaMarker = L.icon({
    iconUrl: require('../images/marker-aqua.png'),
    shadowUrl: require('../images/marker-shadow.png')
});

export function createMap(lat, lng, level, osmUrl, ormUrl, preclick) {
    const map = L.map('mapid', {
        editable: true,
        zoomsliderControl: true,
        zoomControl: false,
    }).setView([lat || DEFAULT_LAT, lng || DEFAULT_LONG], level);
    const {
        backgroundMap,
        openrailwaymap,
    } = initData(osmUrl, ormUrl);

    openrailwaymap.addTo(map);
    backgroundMap.addTo(map);

    map.on('preclick', preclick);

    L.control.scale({ imperial: false, updateWhenIdle: true }).addTo(map);
    L.Control.boxzoom({ position:'topleft' }).addTo(map);
    const provider = new OpenStreetMapProvider();

    const searchControl = window.searchControl = new GeoSearchControl({
        provider: provider,
        position: 'topright',
        style: 'active',
        searchLabel: 'Szukaj',
        notFoundMessage: 'Nie znaleziono adresu',
    });

    map.addControl(searchControl);
    return map;
}


export function createZone(zone, onClick) {
    const { id, name, color, description, geo_data, type } = zone;
    const polygon2 = {
        type: 'Polygon',
        coordinates: [geo_data]
    };
    const geoJSONzone = decode(polygon2);
    const polygon  = L.polygon(geoJSONzone['coordinates'],{
        color: color || 'blue',
    });
    polygon.on('click', onClick);
    //polygon.bindTooltip(polygonTooltip(zone));
    return polygon;
};

function betterCoords(data) {
    const lat = data.plausible_lat || data.lat;
    const lng = data.plausible_long || data.lng;
    return [lat, lng]
}

export function createWagon(wagon, onClick, onDblclick = nil) {
    const marker = L.marker(betterCoords(wagon.current_asset_state), {icon: aquaMarker})
    marker.on('click', onClick);
    marker.on('dblclick', onDblclick);
    marker.bindTooltip(wagonTooltip(wagon));
    return marker;
};

export function createAllStateWagon(state, internalId, onClick, onDblclick = nil) {
    const marker = L.circleMarker(betterCoords(state), {
        time: state.timestamp,
        color: 'blue',
        fillColor: '#302696',
        fillOpacity: 0.6,
        radius: 10
    });
    // if(state.course!==null) { //DEFECT COLOR
    //     circle.options.icon = redMarker;
    // }
    marker.on('click', onClick);
    marker.on('dblclick', onDblclick);
    marker.bindTooltip(stateTooltip(state, internalId));
    return marker;
};

export function initData(osmUrl, ormUrl) {
    const openrailwaymap = new L.TileLayer(ormUrl,
        {
            attribution: '<a href="http://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>, Style: <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA 2.0</a> <a href="http://www.openrailwaymap.org/">OpenRailwayMap</a> and OpenStreetMap',
            minZoom: 2,
            maxZoom: 18,
            tileSize: 256,
            opacity: 0.8,
        }
    );

    const backgroundMap = new L.TileLayer(osmUrl, {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 18,
        minZoom: 0,
        opacity: 0.8,
        tileSize: 256,
    });

    return {
        backgroundMap,
        openrailwaymap,
    };
}

export function wagonTooltip(wagon) {
    return `
    <div>
      <div>
        Numer wagonu: ${wagon.internal_id}
      </div>
      <div>
        ${wagon.cargo_type}
      </div>
      <div>
        Stan z: ${wagon.current_asset_state.updated_at}
      </div>
      <div>
        ${parseFloat(wagon.current_asset_state.speed).toFixed(2)} km/h
      </div>
      <div>
        ${wagon.current_asset_state.zone}
      </div>
    </div>
  `;
};

function stateTooltip(state, internalId) {
    return `
    <div>
      <div>
        Numer wagonu: ${internalId}
      </div>
      <div>
        Stan z: ${state.timestamp}
      </div>
      <div>
        ${parseFloat(state.speed).toFixed(2)} km/h
      </div>
    </div>
  `;
};

export function polygonTooltip(zone) {
    return `
    <div>
      <div>
        Nazwa: ${zone.name}
      </div>
      <div>
        Wewnętrzne id: ${zone.id}
      </div>
      <div>
        Opis: ${zone.description || ''}
      </div>
    </div>
  `;
};
