<template>
    <div>
        <p>{{ message }}</p>
        <p>Prop value: {{someValue}}</p>
        <p>{{$t('nested_translation.one')}}</p>
        <p>{{$t('nested_translation.two')}}</p>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                message: "Hello Vue!"
            }
        },
        props: {
            someValue: String
        },
        mounted() {
            console.log("Vue app mounted!")
        },
        destroyed() {
          console.log("vue app destroyed")
        }
    }
</script>

<style scoped>
    p {
        font-size: 2em;
        text-align: center;
    }
</style>
