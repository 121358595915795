<template>
    <div class="ZoneDescription" v-if="clickedZoneId">
        <div>
            {{$t('js.zone_description.name')}}: {{zone.name}}
        </div>
        <div>
            {{$t('js.zone_description.id')}}: {{zone.id}}
        </div>
        <div>
            {{$t('js.zone_description.description')}}: {{zone.description}}
        </div>
        <div>
            {{$t('js.zone_description.assets')}}: {{zone.assets_names}}
        </div>
        <div v-if="isAdmin">
            <a :href="'/admin/zones/'+clickedZoneId+'/edit'">
                <button class="btn btn-secondary btn-small">{{$t('js.zone_description.edit')}}</button>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            clickedZoneId: Number,
            isAdmin: Boolean,
            zones: Array,
        },
        data () {
            return {
                zone: null,
            }
        },
        watch: {
          clickedZoneId: function (newVal) {
              this.zone = this.zones.find(zone => zone.id === this.$props.clickedZoneId);
          }
        }
    }
</script>
