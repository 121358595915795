export const TASK_TYPES = {
    /**
     * Odczyt konfiguracji urządzenia
     */
    rcfg: "rcfg",
    /**
     * Zmiana konfiguracji urządzenia
     */
    wcfg: "wcfg",
    /**
     * Przesłanie informacji na temat dostępnej aktualizacji
     */
    res_update_info: "res_update_info",
    /**
     * Wymuszenie restartu urządzenia
     */
    reset_transmitter: "reset_transmitter",
    /**
     * Wymuszenie resetu stanu baterii
     */
    reset_battery: "reset_battery"
}

export const TASK_STATUSES = {
    sent: "sent",
    awaiting_connection: "awaiting_connection",
    pending: "pending",
    error: "error",
    canceled: "canceled",
    completed: "completed",
    update_rejected: "update_rejected",
    update_in_progress: "update_in_progress"
}

export const RES_KINDS = {
    firmware: "firmware",
    epo: "epo"
}