import Alerts from './alerts';
import { EventBus } from "./event-bus";

const AlertsPlugin = {
    install(Vue) {
        // attach these methods with Vue instance
        Vue.prototype.$alert = {
            send(message, type, options) {
                EventBus.$emit("alert-message", {
                    message: message,
                    type: type,
                    options: options
                });
            },

            success(message, options = {}) {
                this.send(message, "success", options);
            },

            warning(message, options = {}) {
                this.send(message, "warning", options);
            },

            info(message, options = {}) {
                this.send(message, "info", options);
            },

            error(message, options = {}) {
                this.send(message, "danger", options);
            }
        };

        // register the component
        Vue.component("alerts", Alerts);
    }
};

export default AlertsPlugin;