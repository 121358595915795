<template>
    <div class="d-flex flex-column flex-grow-1">
        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col">{{$t('js.transmitter.tasks.type')}}</th>
                <th scope="col">{{$t('js.transmitter.tasks.date')}}</th>
                <th scope="col">{{$t('js.transmitter.tasks.resp_date')}}</th>
                <th scope="col">{{$t('js.transmitter.tasks.status')}}</th>
                <th scope="col" style="min-width: 96px;"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="task in tasks">
                <td>{{$t('js.transmitter.tasks.task_type.' + task.task_type)}}<span v-if="hasVersionInfo(task)">: <strong>{{task.res_file_name}}</strong></span></td>
                <td>{{formatDate(task.created_at)}}</td>
                <td>{{formatDate(task.resp_date)}}</td>
                <td>{{$t('js.transmitter.tasks.task_status.' + task.task_status).toUpperCase()}}<span v-if="hasError(task)">: <strong>{{task.error_msg}}</strong></span></td>
                <td class="text-right">
                    <button v-if="hasConfig(task)" class="btn btn-secondary btn-sm" @click="showConfig(task)" data-toggle="modal" data-target="#configModal">
                        <span class="fas fa-file-alt fa-fw" aria-hidden="true"/>
                    </button>
                    <button v-if="canCancel(task)" class="btn btn-danger btn-sm" @click="removeTask(task.id)">
                        <span class="fas fa-times fa-fw" aria-hidden="true"/>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
        <!-- Config Modal -->
        <div class="modal fade" id="configModal" tabindex="-1" role="dialog" aria-labelledby="configModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{$t('js.transmitter.sent_config')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <textarea rows="20" :value="selectedConfig" class="px-2" readonly> </textarea>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">{{$t('js.transmitter.close')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dateFormatter from './mixins/date-formatter'
import errorHandlingMixin from "./mixins/error-handling-mixin";
import AxiosService from "../services/axios-service";
import {TASK_STATUSES, TASK_TYPES} from "../commons/constants";
import Utils from "../services/utils";
export default {
    name: "transmitter-actions",
    mixins: [errorHandlingMixin, dateFormatter],
    props: {
        tasks: {
            type: Array
        }
    },
    data: function () {
        return {
            selectedConfig: null
        }
    },
    mounted() {
        $('#configModal').on('hidden.bs.modal', this.clearSelectedConfig)
    },
    destroyed() {
        $('#configModal').off('hidden.bs.modal', this.clearSelectedConfig);
    },
    methods: {
        canCancel(task) {
          return task.task_status === TASK_STATUSES.pending
              || task.task_status === TASK_STATUSES.awaiting_connection
              || task.task_status === TASK_STATUSES.sent
              || task.task_status === TASK_STATUSES.update_in_progress;
        },
        hasVersionInfo(task) {
          return task.task_type === TASK_TYPES.res_update_info && task.res_file_name;
        },
        hasError(task) {
          return task.task_status === TASK_STATUSES.error && task.error_msg;
        },
        hasConfig(task) {
          return task.task_type === TASK_TYPES.wcfg && task.transmitter_config;
        },
        async removeTask(id) {
            try {
                let axios = AxiosService.instance();
                let res;
                let editedTask = {
                    task_status: TASK_STATUSES.canceled,
                };
                res = await axios.put(`${Utils.getPath()}/${id}`, editedTask);
                Turbolinks.visit(res.data.location);
            } catch (err) {
                this.handleAxiosErrors(err);
            }
        },
        showConfig(task) {
            this.selectedConfig = task.transmitter_config
        },
        clearSelectedConfig() {
          this.selectedConfig = null;
        }
    }
}
</script>

<style scoped>
    tr {
        height: 48px;
    }
    textarea {
        width: 100%;
    }
</style>