<template>
  <div class="row m-0">
    <div class="col-xl-4 col-lg-12">
      <div class="static-area">
        <div class="row">
          <div class="col-12 mb-2">
            <div :style="styleAssetId">
              <h2>{{asset.internal_id}}</h2>
            </div>
          </div>
          <div class="col-12" v-if="all_asset_states[statesTimestampsIndex-1] && all_asset_states[statesTimestampsIndex-1].city">
            {{lastReportDate+', ' + all_asset_states[statesTimestampsIndex-1].city + ', ' +all_asset_states[statesTimestampsIndex-1].state + ', ' +all_asset_states[statesTimestampsIndex-1].country}}
          </div>
          <div class="col-12">
            <span v-if="asset.asset_type">{{asset.asset_type.name+', ' + asset.cargo_type.name}}</span>
            <a  v-if="isAdmin" class="ml-5" :href="editUrl">{{$t('js.asset_state.edit')}}<i class="fas fa-edit"></i> </a>
          </div>
          <div class="col-12"> <!--TODO In straight line title="Odległość w linii prostej"-->
            <b>{{$t('js.asset_state.zone.name')}}:</b> <span v-html="printableZone"></span>
          </div>
          <div class="col-6 justify-content-start">
            <b>{{$t('js.asset_state.kilometrage')}}:</b> {{ printableKilometrage }}
          </div>
          <div class="col-6 justify-content-end">
            <b>{{$t('js.asset_state.inspection_date')}}:</b> <span :style="styleReportDate">{{asset.inspection_alarm.date_of_inspection}}</span>
          </div>
          <div class="col-6 justify-content-start">
            <b>{{$t('js.asset_state.groups')}}:</b> G-11-11-11-11-11-11
          </div>
          <div class="col-6 justify-content-end">
            <b>{{$t('js.asset_state.tranmitter_id.id')}}:</b> {{asset.tracking_device_id}}, <span :style="styleOpMode(asset.op_mode)">{{asset.op_mode}}</span>
            <div v-if="isAdmin">
              <a class="ml-1" :href="transmitterUrl">{{$t('js.asset_state.tranmitter_id.settings')}}<i class="fas fa-cog"></i> </a>
              <a class="ml-2" :href="transmitterLogsUrl">{{$t('js.asset_state.tranmitter_id.logs')}}</a>
            </div>
          </div>
        </div>

        <div class="line mt-1"></div>
        <div class="info-panel">
          {{$t('js.asset_state.no_defects')}}
<!--                    <div v-for="defect in defectList">-->
<!--            Defect detected: {{defect.type}},-->
<!--            <a v-if="defect.timestamp && isAdmin" @click="inputDefectTimestamp(defect.timestamp)" href="">({{defect.timestamp}} <i class="fas fa-eye"></i>), </a>-->
<!--            <span v-else>({{defect.timestamp}}), </span>-->
<!--            Detailed analysis...,-->
<!--            <span v-if="defect.cleared" style="color: #5a6268"> Cleared {{defect.cleared}} </span>-->
<!--            <span v-else-if="isAdmin">-->
<!--                <a href="#" @click="clearDefect(defect);return false;">Clear <i class="fas fa-bell-slash"></i></a>-->
<!--            </span>-->
<!--          </div>-->
        </div>
        <div class="line"></div>
        <div class="info-panel">
          <span style="color: darkorange"> {{$t('js.asset_state.no_warnings')}} </span>
<!--          <div v-for="message in messages">-->
<!--            <span style="color: darkorange"> {{message.severity_level}}: </span> {{ message.date +', '+ message.localization +', '+ message.communicat }}<br>-->
<!--          </div>-->
        </div>
      </div>

      <div class="active-area mt-1">
        <div class="row mb-1 justify-content-center">
          <span style="font-size: 18px">{{$t('js.asset_state.preview_time_range')}}<br>
            {{detailsRange}}</span>
        </div>
        <div class="row">
          <div class="col-5 text-left">
            <div id="slider_from_datetime_group"  data-target-input="nearest">
              <input type="hidden" ref="slider_from_datetime_hidden" class="datetimepicker-input" data-toggle="datetimepicker" data-target="#slider_from_datetime_group">
              <span ref="slider_from_datetime_text" data-target="#slider_from_datetime_group" style="color:black" class="btn">
                  {{oldestReportDate}} <i class="far fa-calendar-alt"></i>
                </span>
            </div>
          </div>
          <div class="col-2 text-center">
            <button class="btn btn-sm btn-secondary" @click="changeSliderRange">{{$t('js.asset_state.chart_actions.apply')}}</button>
          </div>
          <div class="col-5 text-right">
              <div id="slider_to_datetime_group"  data-target-input="nearest">
                <input type="hidden" ref="slider_to_datetime_hidden" class="datetimepicker-input" data-toggle="datetimepicker" data-target="#slider_to_datetime_group">
                <span ref="slider_to_datetime_text" data-target="#slider_to_datetime_group" style="color:black" class="btn">
                  {{newestReportDate}} <i class="far fa-calendar-alt"></i>
                </span>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 preserveAspectRatio="none" viewBox="0 0 500 31" width="100%" height="100%">
              <defs>
                <path d="M0 0L500 0L500 31.01L0 31.01L0 0Z" id="dFKM1p8Mp"></path>
                <path d="M13.13 10.03L23.38 4.5L23.41 15.5L23.44 26.5L13.16 21.03L2.88 15.56L13.13 10.03Z" id="a7tURnTSec"></path>
                <path d="M485.61 20.96L475.36 26.5L475.32 15.5L475.28 4.5L485.56 9.96L495.85 15.42L485.61 20.96Z" id="b81JyDfJd"></path>
              </defs>
              <g>
                <g>
                  <g>
                    <use xlink:href="#dFKM1p8Mp" opacity="1" fill="#96855f" fill-opacity="1"></use>
                    <g>
                      <use @mousemove="mousemove" @mouseup="mouseup" @mousedown="mousedown" ref="sliderSvg" xlink:href="#dFKM1p8Mp" opacity="1" fill-opacity="0" stroke="#ffffff" stroke-width="1" stroke-opacity="1"></use>
                      <line v-for="(timestamp, index) in statesTimestamps" :x1="sliderLeftX+(index)*xSVG+xSVG/2" :x2="sliderLeftX+(index)*xSVG+xSVG/2"
                            y1="0" y2="30" style="stroke-width:2; cursor: pointer"
                            @click="setIndexFromSlider(index+1)" :class="colorLineOnSliderSVG(index)">
                        <title><span>{{ styleSliderTitle(timestamp) }}</span></title>
                      </line>
                      <rect v-if="statesTimestamps.length > 0" height="100%" :x="sliderLeftX+(statesTimestampsIndex-1)*xSVG+xSVG/2-5" rx="5" ref="sliderStateWidget"
                            style="width: 10px; fill: rgba(28, 227, 224,0.75); stroke-width:1; stroke:rgb(150,150,150); cursor: pointer"
                            @mouseup="mouseup" @mousedown="mousedown"/>
                      <line v-if="statesTimestamps.length > 0" :x1="sliderLeftX+(statesTimestampsIndex-1)*xSVG+xSVG/2" :x2="sliderLeftX+(statesTimestampsIndex-1)*xSVG+xSVG/2" y1="-20" y2="50"
                            style="stroke-width:2; cursor: pointer; stroke: #20fff4;" @mouseup="mouseup" @mousedown="mousedown"/>
                    </g>
                  </g>
                  <g @mouseup="getPreviousReport">
                    <use xlink:href="#a7tURnTSec" opacity="1" fill="#ffffff" fill-opacity="1"></use>
                    <g>
                      <use xlink:href="#a7tURnTSec" ref="sliderArrowLeft" opacity="1" fill-opacity="0" stroke="#96855f" stroke-width="1" stroke-opacity="1"></use>
                    </g>
                  </g>
                  <g @mouseup="getNextReport">
                    <use xlink:href="#b81JyDfJd" opacity="1" fill="#ffffff" fill-opacity="1"></use>
                    <g>
                      <use xlink:href="#b81JyDfJd" ref="sliderArrowRight" opacity="1" fill-opacity="0" stroke="#96855f" stroke-width="1" stroke-opacity="1"></use>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div class="d-flex justify-content-between flex-wrap mt-2">
            <button class="mt-1 btn nav-btn btn-sm btn-secondary" @click="getOldestRaport"><i class="fas fa-fast-backward"></i> {{$t('js.asset_state.chart_actions.oldest')}}</button>
            <input ref="reportDate" class="show-report-date" @input="inputReportDate">
            <button @click="getNewestRaport" class="mt-1 btn nav-btn btn-sm btn-secondary float-right">{{$t('js.asset_state.chart_actions.newest')}} <i class="fas fa-fast-forward"></i></button>
        </div>

        <div class="mb-5 mt-1" style="height: 400px;">
          <select v-model="chartType" class="form-control">
            <option v-for="option in chartTypes" v-bind:value="option.value" class="text-center">
              {{option.label}}
            </option>
          </select>
          <asset-chart :index="statesTimestampsIndex-1"
                       :chart-type="chartType"
                       :inode-data="inode"
                       :states="all_asset_states"
                       :kilometres ="kilometres"
                       :is-admin="isAdmin"
                       :transmitter = "transmitter"></asset-chart>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-12">
      <assets-map
          :asset-internal-id="asset.internal_id"
          :namespace = "namespace"
          :wagons = "wagons"
          :is-admin = "isAdmin"
          :zones = "zones"
          :states = "all_asset_states"
          :lat = "lat"
          :lng = "lng"
          :osm-url = "osmUrl"
          :orm-url = "ormUrl"
          :level = "level"
          :slider-index = "statesTimestampsIndex-1"
      ></assets-map>
    </div>
  </div>
</template>

<script>
import AssetChart from "./asset-chart";
import Utils from "../services/utils";
import axios from "axios";

export default {
  name: "assets-state",
  components: {AssetChart},
  props: {
    asset: Object,
    oldest_state: Array,
    transmitter: Object,
    wagons: Array,
    lng: {
      type: Number,
      default: 19.480556,
    },
    lat: {
      type: Number,
      default: 52.06916,
    },
    level: {
      type: Number,
      default: 13,
    },
    isAdmin: Boolean,
    namespace: String,
    osmUrl: String,
    ormUrl: String,
    side: 150,
    position: 140,
    percent: 0,
    alertMessage: String
  },
  data () {
    return {
      all_asset_states: [],
      zones: [],
      inode: [],
      statesTimestamps: [],
      kilometres: null,
      statesTimestampsIndex: 0,
      detailsRange: '',
      oldestReportDate: '',
      newestReportDate: '',
      lastReportDate: '',
      editUrl: '',
      transmitterUrl: '',
      transmitterLogsUrl: '',
      printableZone: '',
      printableKilometrage: 0.0,
      openDateTimePicker: false,
      urlurl: '',
      xSVG: 0.0,
      sliderLeftX: 0.0,
      sliderRightX: 0.0,
      messages: [
        {
          severity_level: 'Ostrzeżenie',
          date: '2020-06-09 15:34',
          localization: 'Miechów',
          communicat: 'Różnica temperatury między maźnicami większa niż 50st.C'
        },
        {
          severity_level: 'Ostrzeżenie',
          date: '2020-06-09 15:34',
          localization: 'Miechów',
          communicat: 'Różnica temperatury między maźnicami większa niż 50st.C'
        },
        {
          severity_level: 'Ostrzeżenie',
          date: '2020-06-09 15:34',
          localization: 'Miechów',
          communicat: 'Różnica temperatury między maźnicami większa niż 50st.C'
        }
      ],
      defectList:[
        {
          type: 'Flat wheel',
          timestamp: '',
          cleared: ''
        },
        {
          type: 'Flat wheel',
          timestamp: '',
          cleared: ''
        },
        {
          type: 'Flat wheel',
          timestamp: '',
          cleared: ''
        }
      ],
      chartType: 'kilometrage',
      chartTypes: [
        {value: 'kilometrage', label: this.$t('js.asset_state.charts.select.mileage')},
        {value: 'temperature', label: this.$t('js.asset_state.charts.select.temperature')},
        {value: 'speed', label: this.$t('js.asset_state.charts.select.speed')}
      ]
    }
  },
  computed: {
    styleReportDate() {
      return {
        color: new Date().getTime() > new Date(this.asset.inspection_alarm.date_of_inspection).getTime() ? 'red' : 'black'
      };
    },
    styleAssetId() {
      let error = false;
      for (let i = 0; i < this.defectList.length; i++) {
        if (!this.defectList[i].cleared){
          error = true;
          break;
        }
      }
      return { color: error ? 'red' : 'black' };
    }
  },
  async mounted () {
    if(this.alertMessage)
      this.$alert.info(this.alertMessage)
    const url = window.location.search ? Utils.getPath()+'/states'+window.location.search : Utils.getPath()+'/states';
    await axios.get(url).then((res) => {
      this.all_asset_states = res.data.states;
      this.kilometres = res.data.kilometres;
      this.zones = res.data.zones;
      this.inode = res.data.inode;
    }).catch(this.handleAxiosErrors);
    this.all_asset_states.forEach(obj=>{
      this.statesTimestamps.push(obj.timestamp)
    })
    this.oldestReportDate = this.statesTimestamps.length > 0 ? moment(new Date(this.statesTimestamps[0])).format('YYYY.MM.DD HH:mm') : '----.--.-- --:--';
    this.newestReportDate = this.statesTimestamps.length > 0 ? moment(new Date(this.statesTimestamps[this.statesTimestamps.length-1])).format('YYYY.MM.DD HH:mm') : '----.--.-- --:--';

    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(this.statesTimestamps[0]);
    const secondDate = new Date(this.statesTimestamps[this.statesTimestamps.length-1]);
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    this.detailsRange = (diffDays ? diffDays : 0) + ' ' + this.$t('js.asset_state.days') +', '+this.statesTimestamps.length + ' ' + this.$t('js.asset_state.reports')
    this.sliderLeftX = this.$refs.sliderArrowLeft.getBoundingClientRect().width + 10
    this.sliderRightX = this.$refs.sliderArrowRight.getBoundingClientRect().width + 10
      // 500 is the width of sliderSvg
    this.xSVG = this.statesTimestamps.length !== 0 ? (500 - this.sliderLeftX - this.sliderRightX)/this.statesTimestamps.length : 1
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get('indexstate')){
      this.statesTimestampsIndex = parseInt(urlParams.get('indexstate'));
      this.setInputReportDate();
    } else if(urlParams.get('slider_range')==='to'){
      this.statesTimestampsIndex = this.statesTimestamps.length-3;
      this.$refs.reportDate.value = moment(new Date(this.statesTimestamps[this.statesTimestamps.length-3])).format('YYYY.MM.DD HH:mm');
    } else {
      this.statesTimestampsIndex=this.statesTimestamps.length
      this.setInputReportDate();
    }
    if (!this.statesTimestamps[this.statesTimestampsIndex-1])
      this.$refs.reportDate.value = '';

    this.setLastReportDate();
    this.editUrl = this.asset.id+'/edit'
    this.transmitterUrl = this.asset.id+'/transmitter'
    this.transmitterLogsUrl = this.asset.id+'/transmitter_logs'
    this.setPrintableZone()
    this.printableKilometrage = this.asset? (this.asset.total_kilometrage/1000).toFixed(2) : 0.0;

    $('#slider_from_datetime_group').datetimepicker({
      format: "YYYY-MM-DD HH:mmZ",
      locale: this.$i18n.locale,
      useCurrent: false,
      minDate: this.oldest_state[0] ? moment(new Date(this.oldest_state[0].timestamp)) : moment(new Date()),
      defaultDate: this.statesTimestamps[0] ? moment(new Date(this.statesTimestamps[0])) : moment(new Date()),
      maxDate: moment(new Date()),
      icons: {
        time: 'far fa-clock',
        date: 'far fa-calendar-alt',
        up: 'fas fa-arrow-up',
        down: 'fas fa-arrow-down'
      }
    });
    this.$refs.slider_from_datetime_text.addEventListener('click', () => {
      $("#slider_from_datetime_group").datetimepicker('toggle');
    });
    $("#slider_from_datetime_group").on("change.datetimepicker", ({date}) => {
      this.oldestReportDate = moment(new Date(date)).format('YYYY.MM.DD HH:mm')
    })

    $('#slider_to_datetime_group').datetimepicker({
      format: "YYYY-MM-DD HH:mmZ",
      locale: this.$i18n.locale,
      useCurrent: false,
      minDate: this.oldest_state[0] ? moment(new Date(this.oldest_state[0].timestamp)) : moment(new Date()),
      defaultDate: this.statesTimestamps[this.statesTimestamps.length-1] ? moment(new Date(this.statesTimestamps[this.statesTimestamps.length-1])) : moment(new Date()),
      maxDate: moment(new Date()),
      icons: {
        time: 'far fa-clock',
        date: 'far fa-calendar-alt',
        up: 'fas fa-arrow-up',
        down: 'fas fa-arrow-down'
      }
    });
    this.$refs.slider_to_datetime_text.addEventListener('click', () => {
      $("#slider_to_datetime_group").datetimepicker('toggle');
    });
    $("#slider_to_datetime_group").on("change.datetimepicker", ({date}) => {
      this.newestReportDate = moment(new Date(date)).format('YYYY.MM.DD HH:mm')
    })

    /*temporary defects dataset*/
      if (this.statesTimestamps.length > 0) {
          this.defectList[2].timestamp=moment(new Date(this.statesTimestamps[0])).format('YYYY-MM-DD HH:mm')
          this.defectList[2].cleared=moment(new Date(this.statesTimestamps[0])).format('YYYY-MM-DD HH:mm')
          let middleIndex = this.statesTimestamps.length>2 ? Math.round(this.statesTimestamps.length/2) : 0
          this.defectList[1].timestamp=moment(new Date(this.statesTimestamps[middleIndex])).format('YYYY-MM-DD HH:mm')
          this.defectList[1].cleared=moment(new Date(this.statesTimestamps[middleIndex])).format('YYYY-MM-DD HH:mm')
          this.defectList[0].timestamp=moment(new Date(this.statesTimestamps[this.statesTimestamps.length-1])).format('YYYY-MM-DD HH:mm')
          this.defectList[0].cleared=moment(new Date(this.statesTimestamps[this.statesTimestamps.length-1])).format('YYYY-MM-DD HH:mm')
      }
    /*end of dataset*/
  },
  methods: {
    styleOpMode(val) {
      return {color: val === 'SERVICE' || val === 'CONTINUOUS' ? 'red' : 'black'}
    },
    clearDefect(e){
      e.cleared = new Date();
    },
    getOldestRaport(){
      this.statesTimestampsIndex = 1;
      this.setInputReportDate();
      this.setLastReportDate();
      this.setPrintableZone();
    },
    getNewestRaport(){
      this.statesTimestampsIndex = this.all_asset_states.length;
      this.setInputReportDate();
      this.setLastReportDate();
      this.setPrintableZone();
    },
    getPreviousReport() {
      this.statesTimestampsIndex = (this.statesTimestampsIndex-1) > 0 ? this.statesTimestampsIndex-1 : this.statesTimestampsIndex;
      this.setInputReportDate();
      this.setLastReportDate();
      this.setPrintableZone();
     },
    getNextReport() {
      if((this.statesTimestampsIndex+1) <= (this.statesTimestamps.length)){
        this.statesTimestampsIndex++;
        this.setInputReportDate();
        this.setLastReportDate();
        this.setPrintableZone();
      }
    },
    changeSliderRange(type = '', date = null) {
      let str = window.location.search
      let date2 = moment(date, [
          'YYYY-MM-DD HH:mm', 'YYYY MM DD HH:mm', 'YYYY.MM.DD HH:mm', 'YYYY/MM/DD HH:mm',
          'DD-MM-YYYY HH:mm', 'DD MM YYYY HH:mm', 'DD.MM.YYYY HH:mm', 'DD/MM/YYYY HH:mm',
          'DD-MM HH:mm'])
      date = date ? new Date(date2).toJSON() : null;
      if(type==='from') {
        str = this.replaceQueryParam('slider_from_datetime', date, str)
        str = this.replaceQueryParam('slider_range', 'from', str)
        str = this.replaceQueryParam('datetime', date, str)
        str = this.replaceQueryParam('indexstate', 4, str)
        str = this.removeParam('slider_to_datetime',str)
      } else if(type==='to') {
        str = this.replaceQueryParam('slider_to_datetime', date, str)
        str = this.replaceQueryParam('slider_range', 'to', str)
        str = this.replaceQueryParam('datetime', date, str)
        str = this.removeParam('indexstate',str)
        str = this.removeParam('slider_from_datetime',str)
      } else {
        str = this.removeParam('slider_range',str)
        str = this.replaceQueryParam('slider_from_datetime', new Date(this.$refs.slider_from_datetime_hidden.value).toJSON(), str)
        str = this.replaceQueryParam('slider_to_datetime', new Date(this.$refs.slider_to_datetime_hidden.value).toJSON(), str)
        str = this.replaceQueryParam('indexstate', 1, str)
      }
      window.location = window.location.pathname + str
    },
    inputReportDate() {
      let reportDateValue = this.$refs.reportDate.value
      if(new Date(reportDateValue).getTime() < new Date(this.statesTimestamps[0]).getTime()){
        this.$refs.reportDate.addEventListener("keyup", (event) => {
          if (event.code === 'Enter') {
            this.changeSliderRange('from', reportDateValue)
          }
        })
      } else if(new Date(reportDateValue).getTime() > new Date(this.statesTimestamps[this.statesTimestamps.length-1]).getTime()) {
        this.$refs.reportDate.addEventListener("keyup", (event) => {
          if (event.code === 'Enter') {
            this.changeSliderRange('to', reportDateValue)
          }
        })
      } else {
        let temp = this.statesTimestamps.map(d => Math.abs(new Date(reportDateValue).getTime()-new Date(d).getTime()));
        let idx = temp.indexOf(Math.min(...temp));
        this.statesTimestampsIndex=idx+1;
      }
    },
    inputDefectTimestamp(defectTimestamp){
      this.$refs.reportDate.value = defectTimestamp;
      let defectDate = this.$refs.reportDate.value;
      if(new Date(defectDate).getTime() < new Date(this.statesTimestamps[0]).getTime()){
        this.changeSliderRange('from', defectDate)
      } else if(new Date(defectDate).getTime() > new Date(this.statesTimestamps[this.statesTimestamps.length-1]).getTime()) {
        this.changeSliderRange('to', defectDate)
      } else {
        let temp = this.statesTimestamps.map(d => Math.abs(new Date(defectDate).getTime()-new Date(d).getTime()));
        let idx = temp.indexOf(Math.min(...temp));
        this.statesTimestampsIndex=idx+1;
        this.updateParamsURL();
      }
    },
    removeParam(key, sourceURL) {
      let rtn = sourceURL.split("?")[0],
          param,
          params_arr = [],
          queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
      if (queryString !== "") {
        params_arr = queryString.split("&");
        for (let i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0];
          if (param === key) {
            params_arr.splice(i, 1);
          }
        }
        rtn = rtn + "?" + params_arr.join("&");
      }
      return rtn;
    },
    setIndexFromSlider(index){
      this.statesTimestampsIndex = index;
      this.setInputReportDate();
    },
    updateParamsURL(){
      let str = window.location.search
      str = this.replaceQueryParam('datetime', this.statesTimestamps[this.statesTimestampsIndex], str)
      str = this.replaceQueryParam('indexstate', this.statesTimestampsIndex, str)
      window.location = window.location.pathname + str
    },
    replaceQueryParam(param, newval, search) {
      let regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
      let query = search.replace(regex, "$1").replace(/&$/, '');

      return (query.length > 2 ? query + "&" : "?") + (newval ? param + "=" + newval : '');
    },
    styleSliderTitle(timestamp) {
      return moment(new Date(timestamp)).format("YYYY-MM-DD HH:mm")
    },
    colorLineOnSliderSVG(index) {
      return "signalOK"
      // return this.all_asset_states[index].VAL !== null ? "signalDefect" : "signalOK"; TODO (add VAL to a columns variable in AssetsController#states)
    },
    mouseup(event) {
      if (this.ismousedown) {
        this.ismousedown = false
      }
    },
    mousedown(event) {
      if (!this.ismousedown)
        this.ismousedown = true
    },
    mousemove(event) {
      if (this.ismousedown) {
        let index = Math.round((event.layerX-this.sliderLeftX)/this.xSVG);
        this.statesTimestampsIndex = index > 1 ? index < this.statesTimestamps.length ? index : (this.statesTimestamps.length) : 1;
        this.setInputReportDate();
        this.setPrintableZone();
        this.setLastReportDate();
      }
    },
    setPrintableZone() {
      const tooltipText = this.$t('js.asset_state.zone.tooltip')
      if (this.all_asset_states[this.statesTimestampsIndex-1] && (this.all_asset_states[this.statesTimestampsIndex-1].zone || this.all_asset_states[this.statesTimestampsIndex-1].nearest_zone))
        this.printableZone = this.all_asset_states[this.statesTimestampsIndex-1].zone ? this.all_asset_states[this.statesTimestampsIndex-1].zone.name : `<span title="${tooltipText}">` +this.all_asset_states[this.statesTimestampsIndex-1].nearest_zone+`</span>`;
    },
    setLastReportDate() {
      if (this.all_asset_states[this.statesTimestampsIndex-1] && this.all_asset_states[this.statesTimestampsIndex-1].timestamp)
        this.lastReportDate = moment(String(this.all_asset_states[this.statesTimestampsIndex-1].timestamp)).format('YYYY-MM-DD hh:mm');
    },
    setInputReportDate() {
      this.$refs.reportDate.value = moment(new Date(this.statesTimestamps[this.statesTimestampsIndex-1])).format('YYYY.MM.DD HH:mm');
    }
  }
}
</script>

<style scoped>
.line{
  border-top:1px solid black;
  width:100%
}
.static-area{
  background: rgba(165, 165, 165, 0.25);
  position: relative;
  padding-left: 5px;
}
.static-area .info-panel {
    height: 80px;
    overflow-y: scroll;
}
.active-area .nav-btn {
    white-space: nowrap;
    width: 90px;
}
.show-report-date {
  background: rgba(185, 239, 238, 0.4);
  font-size: 20px;
  border-radius: 5px;
  text-align:center;
}
.custom-handle{
  background: rgba(185, 239, 238, 0.4)!important;
}
.signalDefect{
  stroke: #ff0000;
}
.signalOK{
  stroke: #302696;
}
</style>