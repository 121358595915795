<template>
    <div>
        <div v-for="(message, idx) in messages">
            <div :class="getAlertClass(message)">
                {{message.message}}
                <button type="button" class="close" aria-label="Close" @click="closeMessage(message, idx)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from './event-bus.js'

    export default {
        components: {},
        props: {},
        data: function () {
            return {
                messages: []
            }
        },
        created() {
            EventBus.$on('alert-message', this.onAlertMessage)
        },
        mounted() {
        },
        destroyed() {
            EventBus.$off('alert-message', this.onAlertMessage)
        },
        methods: {
            onAlertMessage({message, type, options}) {
                const opts = {
                    timeout: type === "danger" ? 0 : 5000
                };
                Object.assign(opts, options);
                this.addMessage(message, type, opts)
            },
            closeMessage(message, idx) {
                idx = idx || this.messages.indexOf(message);
                this.messages.splice(idx, 1)
            },
            getAlertClass(message) {
                return `alert alert-${message.type}`
            },
            addMessage(message, type, options) {
                const msg = {
                    message,
                    type,
                    options
                };
                this.messages.push(msg);

                if (options.timeout) {
                    setTimeout(()=> {
                        this.closeMessage(msg)
                    }, options.timeout)
                }

            }
        },
    }
</script>

<style scoped>
</style>
