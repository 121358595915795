class Utils {
    static generateId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }
    static getHash() {
        return window.location.hash.split("#")[1];
    }
    static setHash(hash) {
        window.location.hash = hash
    }
    static getPath() {
        return window.location.pathname;
    }
}
export default Utils