<template>
    <div ng-if="asset" class="asset-transmitter d-flex flex-column flex-grow-1">
        <div class="form-group">
            <label>{{$t('js.transmitter.name')}}</label>
            <input class="form-control form-control-sm" type="text" :placeholder="asset.tracking_device_id" readonly>
        </div>
        <div class="form-group">
            <label>{{$t('js.transmitter.soft_ver')}}</label>
            <input class="form-control form-control-sm" type="text" :placeholder="asset.soft_ver" readonly>
        </div>
        <div class="form-group">
            <label>{{$t('js.transmitter.date')}}</label>
            <input class="form-control form-control-sm mr-1" style="flex: 1;" type="text" :placeholder="showDate" readonly>
        </div>
        <div class="form-group">
            <label>{{$t('js.transmitter.last_config')}}</label>
            <textarea rows="20" v-model="config" class="px-2" @input="updateConfigDirty"> </textarea>
        </div>
        <div>
            <button type="button" class="btn btn-secondary btn-sm mb-1" @click="goToTasks">
                {{$t('js.transmitter.tasks.queue')}}
            </button>
            <button type="button" class="float-right btn btn-primary btn-sm ml-1 mb-1" :disabled="!selectedFirmware" data-toggle="modal" data-target="#resUpdateInfoModal">
                {{$t('js.transmitter.update_info')}}
            </button>
            <button type="button" class="float-right btn btn-primary btn-sm ml-1 mb-1" :disabled="!config_dirty" @click="saveConfig">
                {{$t('js.transmitter.save_config')}}
            </button>
            <button type="button" class="float-right btn btn-primary btn-sm ml-1 mb-1" @click="requestConfig">
                {{$t('js.transmitter.request_config')}}
            </button>
            <button type="button" class="float-right btn btn-danger btn-sm mb-1" @click="requestReset">
              {{$t('js.transmitter.request_reset')}}
            </button>
        </div>
        <firmware-upload class="mt-4" @prepareUpdateInfo="prepareUpdateInfo"/>
        <!-- Update Info Modal -->
        <div class="modal fade" id="resUpdateInfoModal" tabindex="-1" role="dialog" aria-labelledby="resUpdateInfoModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{$t('js.transmitter.update_info')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>{{$t('js.transmitter.update.res_file_name')}}</label>
                            <input class="form-control form-control-sm" type="text" :placeholder="selectedFirmware" readonly>
                            <small class="form-text text-muted">{{$t('js.transmitter.field_required')}}</small>
                        </div>
                        <div class="form-group">
                            <label>{{$t('js.transmitter.update.res_kind')}}</label>
                            <select v-model="updateInfoToSend.res_kind" class="form-control text-capitalize">
                                <option v-for="kind in res_kinds">
                                    {{kind}}
                                </option>
                            </select>
                            <small class="form-text text-muted">{{$t('js.transmitter.field_required')}}</small>
                        </div>
                        <div class="form-group">
                            <label>{{$t('js.transmitter.update.res_version')}}</label>
                            <input class="form-control form-control-sm" type="text" v-model="updateInfoToSend.res_version">
                            <small class="form-text text-muted">{{$t('js.transmitter.field_required')}}</small>
                        </div>
                        <div class="form-group">
                            <label>{{$t('js.transmitter.update.start_date')}}</label>
                            <input v-model="updateInfoToSend.update_start_date" type="text" class="form-control datetimepicker-input" id="startDatePicker" data-toggle="datetimepicker" data-target="#startDatePicker"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">{{$t('js.transmitter.close')}}</button>
                        <button type="button" class="float-right btn btn-primary btn-sm ml-1 mb-1"  @click="sendUpdateInfo">
                            {{$t('js.transmitter.send_update_info')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FirmwareUpload from "./firmware-upload";
import errorHandlingMixin from './mixins/error-handling-mixin'
import dateFormatter from './mixins/date-formatter'
import AxiosService from "../services/axios-service";
import Utils from "../services/utils";
import {TASK_TYPES, RES_KINDS} from "../commons/constants"
const JSON5 = require('json5');
export default {
    name: "transmitter-configuration-panel",
    mixins: [errorHandlingMixin, dateFormatter],
    components: { FirmwareUpload },
    props: {
        asset: {
            type: Object
        }
    },
    data: function () {
        return {
            config: null,
            selectedFirmware: null,
            updateInfoToSend: {},
            config_dirty: false,
            res_kinds: []
        }
    },
    computed: {
        showDate() {
          return this.formatDate(this.asset.config_date)
        }
    },
    mounted() {
        $('#resUpdateInfoModal').on('hidden.bs.modal', this.clearUpdateInfo);
        $('#startDatePicker').datetimepicker({
            format: "YYYY-MM-DD HH:mm:ss",
            locale: this.$i18n.locale,
            icons: {
                time: "fa fa-clock"
            }
        });
        this.dp = $('#startDatePicker').data('datetimepicker');
        $('#startDatePicker').on('change.datetimepicker', this.onChangeDP);
        this.res_kinds = Object.values(RES_KINDS);
        if (this.asset.config) {
            this.config = JSON.stringify(JSON.parse(this.asset.config), null, 4);
        }
    },
    destroyed() {
        $('#resUpdateInfoModal').off('hidden.bs.modal', this.clearUpdateInfo);
        $('#startDatePicker').off('change.datetimepicker', this.onChangeDP);
    },
    methods: {
        saveConfig() {
            try {
                const newTask = {
                    task_type: TASK_TYPES.wcfg,
                    transmitter_config: JSON.stringify(JSON.parse(this.config), null, 4)
                };
                this.createTask(newTask);
            } catch(e){
                this.$alert.error(this.$t('js.transmitter.last_config') + ": " + this.$t('js.errors.format_error'));
            }
        },
        requestConfig() {
            const newTask = {
                task_type: TASK_TYPES.rcfg,
            };
            this.createTask(newTask);
        },
        requestReset() {
          const newTask = {
            task_type: TASK_TYPES.reset_transmitter,
          };
          this.createTask(newTask);
        },
        prepareUpdateInfo (firmware) {
            this.selectedFirmware = firmware.version;
        },
        sendUpdateInfo() {
            const newTask = {
                task_type: TASK_TYPES.res_update_info,
                res_version: this.updateInfoToSend.res_version,
                res_kind: this.updateInfoToSend.res_kind,
                res_file_name: this.selectedFirmware,
                update_start_date: this.updateInfoToSend.update_start_date
            };
            this.createTask(newTask);
            $('#resUpdateInfoModal').modal('hide');
        },
        async createTask(newTask) {
            try {
                let axios = AxiosService.instance();
                let res;
                res = await axios.post(Utils.getPath() + '/transmitter_tasks', newTask);
                Turbolinks.visit(res.data.location);
            } catch (err) {
                this.handleAxiosErrors(err);
            }
        },
        clearUpdateInfo() {
            this.updateInfoToSend = {};
        },
        onChangeDP(event) {
            this.updateInfoToSend.update_start_date = event.date ? event.date.format(this.dp.format()) : null;
        },
        goToTasks() {
            Turbolinks.visit(Utils.getPath() + '/transmitter_tasks');
        },
        updateConfigDirty() {
            this.config_dirty = true
        }
    }
}
</script>

<style>
    .asset-transmitter textarea {
        width: 100%;
    }
    .asset-transmitter .btn:disabled {
        cursor: auto;
    }
    .asset-transmitter .bootstrap-datetimepicker-widget a[data-action] {
        color: #007bff;;
    }
</style>