<template>
  <div style="height: 100%">
    <div v-if="loading" class="fa-3x d-flex flex-row justify-content-center align-items-center" style="height: 100%">
      <i class="fas fa-cog fa-spin" style="color: #96855f"></i>
    </div>
    <div v-show="!loading" style="height: 100%">
      <canvas ref="chart" id="chartId" height="100%"></canvas> <!--  -->
      <button v-if="!fullPage" type="button" @click="openChart" :style="{'position': 'absolute', 'right':'25px', 'bottom': '75px',
        'background': 'transparent', 'border': 'none', 'cursor': 'pointer', '-webkit-text-stroke': '0.75px white'}" >
        <i class="fas fa-expand" style="font-size: 18px"></i>
      </button>
    </div>
  </div>
</template>
<script>
import Utils from "../services/utils";
export default ({
  name: "chart",
  props: {
    chartType: String,
    inodeData: Array,
    states: Array,
    index: Number,
    transmitter: Object,
    fullPage: {
      type: Boolean,
      default: false
    },
    isAdmin: Boolean,
    kilometres: Object //Received
  },
  data () {
    return {
      colors: ["red","blue","green","yellow","purple","DimGrey","aqua","olive", "#00ff00"],
      chartData: {
        datasets: []
      },
      options: {
        animation: {
          duration: 0
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              steps:10,
              min: 0,
              max: 70,
              beginAtZero: true
            }
          }],
          xAxes: [ {
            type: 'time',
            time: {
                unit: 'day',
                displayFormats: {
                  day: 'D-MM-YYYY'
                }
            },
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      },
      sensors: [],
      speed: [],
      chart: null,
      loading: false
    }
  },
  mounted () {
    if(this.transmitter.config) {
      for (const [key, value] of Object.entries(this.transmitter.config)) {
        if (value !== "") {
          this.sensors.push({label: key, id: value, data: []})
        }
      }
    } else {
      this.$alert.warning(this.$t('js.asset_state.chart_notifications.configuration_not_found', {transmitter_id: this.transmitter.transmitter_id}))
    }
    this.chart = new Chart(this.$refs["chart"], {type: 'scatter', data: this.chartData, options: this.options})
    this.loading = true;



    if(this.fullPage) {
      const url = new URL(window.location.href);
      const type = url.searchParams.get("type");
      if (type === 'speed') {
        this.prepareSpeedData();
      } else if (type === 'temperature') {
        this.prepareTemperatureData();
      } else if (type === 'kilometrage') {
        this.prepareKilometresData();
      }
    }
  },
  methods: {
    prepareKilometresData() {
      this.chartData.datasets.length = 0;
      const kilometresData = [];
      let maxValue = 0;
      for (const [date, value] of Object.entries(this.kilometres)) {
        kilometresData.push({
          x: new Date(date),
          y: value.toFixed(2)
        })
        if (value > maxValue) {
          maxValue = value
        }
      }
      const datasetObject = {};
      datasetObject["type"] = 'bar';
      datasetObject["backgroundColor"] = 'green';
      datasetObject["label"] = this.$t('js.asset_state.charts.labels.mileage');
      datasetObject["data"] = kilometresData;
      this.chart.options.legend.display = this.fullPage;
      this.chart.options.scales.yAxes[0].ticks.min = 0;
      this.chart.options.scales.yAxes[0].ticks.max = maxValue;
      this.chartData.datasets.push(datasetObject)
      this.chart.update();
      this.loading = false;
    },
    prepareSpeedData() {
      this.chartData.datasets.length = 0;
      const speedData = []
      this.states.forEach((data, dataIndex) => {
        speedData.push({
          x: new Date(data.timestamp),
          y: parseFloat(data.speed).toFixed(2)
        })
      })
      const datasetObject = {};
      datasetObject["backgroundColor"] = 'darkorange';
      datasetObject["label"] = this.$t('js.asset_state.charts.labels.speed');
      datasetObject["data"] = speedData;
      datasetObject["pointStyle"] = 'rect';
      this.chartData.datasets.push(datasetObject)
      this.chart.options.legend.display = this.fullPage;
      this.chart.options.scales.yAxes[0].ticks.min = 0;
      this.chart.options.scales.yAxes[0].ticks.max = 130;
      this.chart.update();
      this.loading = false;
    },
    prepareTemperatureData() {
      let maxTemp = 0, minTemp = 0;
      const unassignedSensors = [];
      this.chartData.datasets.length = 0;
      this.sensors.length = 0;
      if (!this.transmitter.config) {
        this.sensors[0] = {data: [], label: this.$t('js.asset_state.chart_notifications.configuration_not_found', {transmitter_id: this.transmitter.transmitter_id})}
      } else {
        for (const [key, value] of Object.entries(this.transmitter.config)) {
          if (value !== "") {
            this.sensors.push({label: key, id: value, data: []})
          }
        }
        this.sensors.push({label: this.$t('js.asset_state.charts.labels.environment'), id: 'environment', data: []})
        this.inodeData.forEach((data, dataIndex) => {
          if (data) {
            const measurementIndex = this.sensors.findIndex(x => x.id === 'environment');
            this.sensors[measurementIndex].data.push({
              x: new Date(data.measurement.timestamp),
              y: data.measurement.temp
            })
            if (data.axles && data.axles.results) {
              for (const [key, value] of Object.entries(data.axles.results)) {
                const sensorAddress = value.address.replace(/:/g, "")
                const index = this.sensors.findIndex(x => x.id === sensorAddress);
                if (index === -1 && this.sensors.length > 0 && !unassignedSensors.includes(sensorAddress)) {
                  unassignedSensors.push(sensorAddress)
                } else if (index !== -1) {
                  const temperature = value.temperature.toFixed(2)
                  if (maxTemp < temperature) {
                    maxTemp = temperature;
                  }
                  if (minTemp > temperature) {
                    minTemp = temperature;
                  }
                  this.sensors[index].data.push({
                    x: new Date(this.inodeData[dataIndex].axles.timestamp),
                    y: temperature
                  })
                }
              }
            }
          }
        })
      }
      if(!this.fullPage && this.isAdmin) {
        unassignedSensors.forEach((id) => {
          this.$alert.warning(this.$t('js.asset_state.chart_notifications.address_not_found', {sensor_address: id}))
        })
      }
      this.sensors.forEach((sensor, index) => {
        const datasetObject = {};
        datasetObject["backgroundColor"] = sensor.id === 'environment' ? this.colors[this.colors.length-1] : this.colors[index];
        datasetObject["label"] = sensor.label;
        datasetObject["data"] = sensor.data;
        datasetObject["sensorId"] = sensor.id;
        this.chartData.datasets.push(datasetObject)
      })
      this.chart.options.legend.display = true;
      this.chart.options.scales.yAxes[0].ticks.min = minTemp-10 > 0 ? minTemp-10 : 0;
      this.chart.options.scales.yAxes[0].ticks.max = maxTemp > 70 ? Math.ceil(maxTemp+10) : 70;
      this.chart.update();
      this.loading = false;
    },
    openChart() {
      const operator = window.location.search ? `&` : `?`
      window.open(`${Utils.getPath()}/chart${window.location.search+operator}type=${this.chartType}`, '_blank')
    }
  },
  watch: {
    states() {
      if (this.chartType === 'speed') {
        this.loading = true;
        this.prepareSpeedData();
      }
    },
    inodeData() {
      if (this.chartType === 'temperature') {
        this.loading = true;
        this.prepareTemperatureData();
      }
    },
    kilometres() {
      if (this.chartType === 'kilometrage') {
        this.loading = true;
        this.prepareKilometresData();
      }
    },
    index: {
      handler(newIndex) {
        if(this.inodeData[newIndex] && this.inodeData[newIndex].results && this.chartType === 'temperature') {
          for (const [key, value] of Object.entries(this.inodeData[newIndex].results)) {
            const sensorAddress = value.address.replace(/:/g,"")
            const index = this.sensors.findIndex(x => x.id === sensorAddress);
            if(index !== -1) {
              const chartIndex = this.chartData.datasets.findIndex(x => x.sensorId === sensorAddress);
              this.chartData.datasets[chartIndex].label = this.sensors[index].label + '(' + value.temperature.toFixed(2) + '°C)'
            }
          }
        } else if (this.chartType === 'temperature') {
          for(let i = 0; i < this.sensors.length; i++) {
            this.chartData.datasets[i].label = this.sensors[i].label
          }
        }
        this.chart.update();
      },
    },
    chartType: {
      handler(type) {
        if (type === 'speed') {
          this.prepareSpeedData();
        } else if (type === 'temperature') {
          this.prepareTemperatureData();
        } else if (type === 'kilometrage') {
          this.prepareKilometresData();
        }
      }
    }
  }
})
</script>