<template>
  <div>
    <table class="table table-bordered">
      <tr>
        <td colspan="2" style="font-size: 1rem">
          {{$t('js.transmitter.externals.temp_sensors')}}
        </td>
      </tr>
      <tr v-for="key of mArray" :key="key">
        <td style="width: 25%; font-size: 1rem; color: #495057;">
          <div class="ml-5 mt-1">
            {{key}}
          </div>
        </td>
        <td>
          <input class="form-control text-center border-0" type="text" v-model="configModel[key]">
        </td>
      </tr>
    </table>
    <button class="btn btn-sm btn-secondary float-left" @click="goBack">{{$t('js.transmitter.externals.back')}}</button>
    <button class="btn btn-sm btn-primary float-right" @click="save">{{$t('js.transmitter.externals.save')}}</button>
  </div>
</template>

<script>
import AxiosService from "../services/axios-service";
import Utils from "../services/utils";

export default {
  name: "transmitter-externals",
  props: {
    config: {
      type: Object
    },
  },
  data: function (){
    return {
      configModel: {},
      mArray: ["M11","M12","M13","M14","M21","M22","M23","M24"]
    }
  },
  mounted() {
    for (const [key, value] of Object.entries(this.config)) {
      const obj = {}
      obj[key] = value;
      this.configModel = {...this.configModel, ...obj};
    }
  },
  methods: {
    async save() {
      try {
        const axios = AxiosService.instance();
        const resp = await axios.put(`${Utils.getPath().replace('/edit', '')}`, {
              transmitter: {
                config: this.configModel
              }
            }
        );
        Turbolinks.visit(resp.data.location);
      } catch (err) {
        this.handleAxiosErrors(err);
      }
    },
    goBack() {
      const url = Utils.getPath();
      const lastIndex = url.lastIndexOf("/");
      window.location.href = url.substring(0, lastIndex);
    }
  },
}
</script>

<style scoped>

</style>