<template>
    <div v-if="componentType">
        <div class="form-group">
            <label>{{$t('js.component_type.name')}}</label>
            <input class="form-control" type="text" v-model="componentType.name">
        </div>
        <div class="form-group">
            <label>{{$t('js.component_type.model')}}</label>
            <input class="form-control" type="text" v-model="componentType.model">
        </div>
        <div class="d-flex flex-wrap align-items-center justify-content-between mb-3">
            <h3 class="mb-0">{{$t('js.component_type.params')}}</h3>
            <button type="button" class="btn btn-success btn-sm" @click="addParam">{{$t('js.component_type.param.add')}}</button>
        </div>
        <div v-for="(param, idx) in paramTypes">
            <div class="form-row align-items-end mb-3" v-if="!param._destroy">
                <div class="col-sm-5">
                    <label>{{$t('js.component_type.param.name')}}</label>
                    <input class="form-control" type="text" v-model="param.name">
                </div>
                <div class="col-sm-5">
                    <label>{{$t('js.component_type.param.measurement_type')}}</label>
                    <select v-model="param.types_of_measurement_id" class="form-control">
                        <option v-for="option in measurementTypes" v-bind:value="option.id">
                            {{option.name}} [{{$t('js.component_type.measurement_type.unit.' + option.unit)}}]
                        </option>
                    </select>
                    </div>
                <div class="col-sm-2">
                    <button class="btn btn-danger float-right btn-sm" @click="removeParam(param, idx)">
                        <span class="fas fa-times fa-fw" aria-hidden="true"/>
                    </button>
                </div>
            </div>
        </div>

        <h3>{{$t('js.component_type.allowed_component_types')}}</h3>
        <table class="table table-striped mt-3">
            <thead>
            <tr>
                <th>
                    <strong>{{$t('js.component_type.name')}}</strong>
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="(typeId, idx) in allowedComponents" :key="idx">
                    <td>{{usedComponentTypesMap[typeId].name}}</td>
                    <td>
                        <div class='float-right'>
                            <button class="btn btn-secondary btn-sm mr-2" @click="onAllowedEdit(typeId)">
                                <span class="fas fa-pencil-alt fa-fw" aria-hidden="true"/>
                            </button>
                            <button class="btn btn-danger btn-sm float-right" @click="onAllowedDelete(idx, typeId)">
                                <span class="fas fa-times fa-fw" aria-hidden="true"/>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="form-row align-items-end mb-3">
            <div class="col-sm-10">
                <label>{{$t('js.component_type.add_allowed')}}</label>
                <v-select v-model="selectedTypeOption" :options="componentTypesOptions" label="name"
                          @search="searchComponentTypes" @search:focus="loadComponentTypes"/>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-secondary float-right btn-sm" @click="addAllowedType">
                    <span class="fas fa-plus fa-fw" aria-hidden="true"/>
                </button>
            </div>
        </div>
        <button type="button" class="btn btn-secondary btn-sm" @click="back">{{$t('js.component_type.back')}}</button>
        <button class="btn btn-primary btn-sm float-right" @click="save">{{$t('js.component_type.save')}}</button>
    </div>
</template>

<script>
    import AxiosService from "../services/axios-service";
    import errorHandlingMixin from "./mixins/error-handling-mixin"

    export default {
        mixins: [errorHandlingMixin],
        data: function () {
            return {
                paramTypes: [],
                allowedComponents: [],
                usedComponentTypesMap: {},
                componentTypesOptions: [],
                selectedTypeOption: null,
            }
        },
        props: {
            componentType: Object,
            measurementTypes: Array,
            url_back: String
        },
        mounted() {
            this.componentType.allowed_component_types.forEach((type) => this.usedComponentTypesMap[type.id] = type);

            this.componentType.params = this.componentType.params || [];

            this.allowedComponents = this.componentType.allowed_component_types.map((type) => type.id);
            this.paramTypes = this.componentType.params;
        },
        methods: {
            async save() {

                let redirectUrl;
                try {
                    let axios = AxiosService.instance();

                    const url = '/admin/component_types';

                    this.componentType.params_attributes = this.componentType.params;
                    delete this.componentType.params;

                    this.componentType.allowed_component_type_ids = this.allowedComponents;
                    delete this.componentType.allowed_component_types;

                    if (this.componentType.id) {
                        let res = await axios.put(`${url}/${this.componentType.id}`, {component_type: this.componentType});
                        redirectUrl = res.data.location
                    } else {
                        let res = await axios.post(url, {component_type: this.componentType});
                        redirectUrl = res.data.location
                    }
                    Turbolinks.visit(redirectUrl)
                } catch (err) {
                    this.handleAxiosErrors(err)
                }
            },
            addParam() {
                const DEFAULT_KEY = '';

                const measurementType = this.measurementTypes.length ? this.measurementTypes[0].id : null;

                this.paramTypes.push({
                    id: null,
                    name: DEFAULT_KEY,
                    types_of_measurement_id: measurementType,
                })
            },
            removeParam(param, idx) {
                console.log('destroy', idx, param)
                if (param.id) {
                    this.$set(param, '_destroy', true);
                } else {
                    this.paramTypes.splice(idx, 1)
                }
            },
            addAllowedType() {
                let selected = this.selectedTypeOption;

                if (selected && !this.usedComponentTypesMap[selected.id]) {
                    this.usedComponentTypesMap[selected.id] = selected;
                    this.allowedComponents.push(selected.id)
                }
            },
            onAllowedDelete(idx, id) {
                delete this.usedComponentTypesMap[id];
                this.allowedComponents.splice(idx, 1)
            },
            onAllowedEdit(id) {
                const url = '/admin/component_types';
                Turbolinks.visit(`${url}/${id}/edit`)
            },
            searchComponentTypes(search, loading) {
                // TODO ws search
                // console.log('search', search)
            },
            async loadComponentTypes() {
                if (!this.componentTypesOptions.length) {
                    let axios = AxiosService.instance();
                    const url = '/admin/component_types/all';
                    await axios.get(url)
                        .then((res) => {
                            this.componentTypesOptions = res.data
                        })
                        .catch(this.handleAxiosErrors);
                }
            },
            back () {
                const url = this.url_back.substr(this.url_back.indexOf('/admin'));
                Turbolinks.visit(`${url}`)
            }
        }
    }
</script>

<style scoped>
    .table {
        border-bottom: 1px solid #dee2e6;
    }
</style>
