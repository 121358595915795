<template>
    <div v-if="selectedComponent" class="d-flex flex-column flex-fill">
        <div class="form-group">
            <label>{{$t('js.asset_components.component.type')}}</label>
            <select v-model="selectedComponent.component_type_id" class="form-control" @change="onChange($event)">
                <option v-for="option in allowedComponentTypes" v-bind:value="option.id">
                    {{option.name}}
                </option>
            </select>
        </div>
        <div class="form-group">
            <label>{{$t('js.asset_components.component.name')}}</label>
            <input class="form-control" type="text" v-model="selectedComponent.name">
        </div>
        <div class="form-group">
            <label>{{$t('js.asset_components.component.model')}}</label>
            <input class="form-control" type="text" v-model="selectedComponent.model">
        </div>
      <div class="form-group">
        <label>{{$t('js.asset_components.component.expected_cost')}}</label>
        <input class="form-control" type="number" step="any" v-model="selectedComponent.expected_cost">
      </div>
      <div class="form-group">
        <label>{{$t('js.asset_components.component.expected_kilometrage')}}</label>
        <input class="form-control" type="number" v-model="selectedComponent.expected_kilometrage">
      </div>
      <div class="form-group">
        <label>{{$t('js.asset_components.component.expected_lifetime')}}</label>
        <input class="form-control" type="number" v-model="selectedComponent.expected_lifetime">
      </div>
        <div class="form-group">
            <label>{{$t('js.asset_components.component.serial_no')}}</label>
            <input class="form-control" type="text" v-model="selectedComponent.serial_no">
        </div>
        <div class="form-group">
            <label>{{$t('js.asset_components.component.production_date')}}</label>
            <input class="form-control" type="text" v-model="selectedComponent.production_date" placeholder="YYYY, YYYY-MM, YYYY-MM-DD">
        </div>
        <div class="mt-auto">
            <button type="button" class="btn btn-primary btn-sm float-right mr-1"
                    @click="saveComponent">
                {{$t('js.asset_components.component.save')}}
            </button>
            <button type="button" class="btn btn-secondary btn-sm" @click="back">
                {{$t('js.asset_components.component.back')}}
            </button>
        </div>
    </div>
</template>

<script>
import errorHandlingMixin from './mixins/error-handling-mixin'
import AxiosService from "../services/axios-service";
import Utils from "../services/utils";

export default {
    mixins: [errorHandlingMixin],
    props: {
        component: Object,
        parent: Object,
        componentTypes: Array,
        order: {
            type: Number,
            default: 0
        },
        internalId: String
    },
    data: function () {
        return {
            componentTypesMap: {},
            selectedComponent: {},
            allowedComponentTypes: [],
        }
    },
    mounted() {
        if (this.component) {
            this.selectedComponent = this.component;
        }
        this.componentTypes.forEach((type) => {
            this.componentTypesMap[type.id] = type
        });
        this.allowedComponentTypes = this.getAllowedComponentTypes();
    },
    methods: {
        getAllowedComponentTypes: function () {
            if (this.parent) {
                let parent = this.parent;
                let allowed = [];
                const type = this.componentTypesMap[parent.component_type_id];
                for (let allowed_type of type.allowed_component_types) {
                    allowed.push(this.componentTypesMap[allowed_type.id])
                }
                return allowed
            } else {
                return this.componentTypes
            }
        },
        async saveComponent() {
            let redirectUrl;
            try {
                let axios = AxiosService.instance();
                let res;
                if (this.selectedComponent.id) {
                    res = await axios.put(`${Utils.getPath().replace("/edit", "")}`, this.selectedComponent);
                } else {
                    this.selectedComponent.order = this.order;
                    this.selectedComponent.parent_id = this.parent ? this.parent.id : null;
                    res = await axios.post(Utils.getPath().replace("/new", ""), this.selectedComponent);
                }
                redirectUrl = res.data.location;
                Turbolinks.visit(redirectUrl);
            } catch (err) {
                this.handleAxiosErrors(err);
            }
        },
        onChange(event) {
            if (!this.selectedComponent.model) {
                const componentType = this.allowedComponentTypes.find(t => t.id.toString() === event.target.value);
                if (componentType) {
                    this.selectedComponent.model = componentType.model
                }
            }
        },
        back () {
            const backUrl = Utils.getPath().substring(0, Utils.getPath().indexOf('components') + 'components'.length);
            Turbolinks.visit(backUrl)
        }
    }
}
</script>

<style>
</style>
