import axios from 'axios'

let _axios = null;

class AxiosService {
    static instance() {
        if (_axios) {
            return _axios
        }

        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        // axios.defaults.headers.common['X-CSRF-Token'] = token;
        // axios.defaults.headers.common['Accept'] = 'application/json';

        _axios = axios.create({
            headers: {
                'X-CSRF-Token': token,
                'Accept': 'application/json'
            },
        });

        return _axios
    }
}

export default AxiosService