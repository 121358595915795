<template>
    <div>
        <h3>{{$t('js.measurements.measurements')}}</h3>
        <a :href="`/admin/assets/${inspection.asset_id}/components`">[{{$t('js.measurements.edit_components')}}]</a>
        <div v-if="components && components.length > 0">
            <div v-for="component in components" class="d-flex flex-column border-bottom py-1 list-item">
                <div class="d-flex justify-content-between align-items-center">
                    <h5><span :style="{ 'padding-left': component.level * 20 + 'px' }">{{component.name}}</span></h5>
                    <button v-if="component.params.length > 0" type="button" class="btn btn-secondary btn-sm"
                            data-toggle="collapse" :data-target="'#params' + component.id" aria-expanded="false">
                        <i class="fas fa-fw fa-level-down-alt"></i>
                    </button>
                </div>
                <div :style="{ 'padding-left': component.level * 20 + 'px' }">
                    <a :href="`/admin/component_types/${component.component_type_id}/edit`">[{{$t('js.measurements.edit_params')}}]</a>
                    <div v-if="component.params && component.params.length">
                        <div v-for="(param, idx) in component.params"
                             class="collapse show mt-2" :id="'params' + component.id">
                            <div class="form-row align-items-end mb-3">
                                <div class="col-sm-4">
                                    <label>{{$t('js.component_type.param.name')}}</label>
                                    <input class="form-control" type="text" :placeholder="param.name" readonly>
                                </div>
                                <div class="col-sm-4">
                                    <label>{{$t('js.component_type.param.measurement_type')}}</label>
                                    <input class="form-control" type="text"
                                           :placeholder="param.types_of_measurement ? param.types_of_measurement.name + ' [' + param.types_of_measurement.unit + ']' : ''"
                                           readonly>
                                </div>
                                <div class="col-sm-4">
                                    <label>{{$t('js.measurements.value')}}</label>
                                    <input class="form-control" type="text" v-model="param.value">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        {{$t('js.measurements.no_params')}}
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            {{$t('js.measurements.no_components')}}
        </div>
    </div>
</template>

<script>
    import errorHandlingMixin from "./mixins/error-handling-mixin"

    export default {
        mixins: [errorHandlingMixin],
        data: function () {
            return {
                measurements: [],
                components: []
            }
        },
        props: {
            componentsTree: Array,
            inspection: Object,
            targetId: String,
            saveButtonId: String,
        },
        mounted() {
            this.saveButton = document.getElementById(this.saveButtonId);
            this.targetEl = document.getElementById(this.targetId);
            if (!this.targetEl) {
                throw new Error('Cannot find target element!')
            }

            if (!this.saveButton) {
                throw new Error('Cannot find save button!')
            }
            this.saveButton.addEventListener('click', this.beforeSaveHandler);

            this.measurements = this.inspection.measurements || [];

            for (let component of this.componentsTree) {
                this.prepareMeasurements(component, this.measurements)
            }
            this.components = this.flatTree();
        },
        destroyed() {
            this.saveButton.removeEventListener('click', this.beforeSaveHandler)
        },
        methods: {
            prepareMeasurements(component, measurements) {
                let component_type_params = component.component_type.params;

                for (let ctp of component_type_params) {
                    let existingParam = measurements.find(m => (m.component_type_param_id === ctp.id) && (m.component_id === component.id));
                    let param = existingParam || {
                        component_id: component.id,
                        component_type_param_id: ctp.id,
                        value: null
                    };
                    if (!existingParam) {
                        measurements.push(param)
                    }
                }

                for (let child of component.children) {
                    this.prepareMeasurements(child, measurements)
                }
            },
            beforeSaveHandler(evt) {
                this.prepareValues();
                this.targetEl.value = JSON.stringify(this.measurements)
            },
            prepareValues() {
                const self = this;
                this.components.forEach(function (component) {
                    component.params.forEach(function (param) {
                        if (param.value && param.value.trim()) {
                            let existingParam = self.measurements.find(m => (m.component_type_param_id === param.id) && (m.component_id === component.id));
                            existingParam.value = param.value;
                        }
                    });
                });
            },
            flatTree() {
                const prepareParams = function (component, measurements) {
                    if (component.component_type && component.component_type.params && component.component_type.params.length) {
                        let params = component.component_type.params;
                        params.forEach(function (param) {
                            let existingParam = measurements.find(m => (m.component_type_param_id === param.id) && (m.component_id === component.id));
                            param.value = existingParam.value;
                        });
                        return params;
                    }
                    return [];
                };
                const buildList = function (item, flatList, level, measurements) {
                    if (!item.children || item.children.length === 0) return;
                    for (var i = 0; i < item.children.length; i++) {
                        let child = item.children[i];
                        flatList.push({
                            id: child.id,
                            component_type_id: child.component_type_id,
                            name: child.name,
                            level: level,
                            params: prepareParams(child, measurements)
                        });
                        buildList(child, flatList, level + 1, measurements);
                    }
                };
                let flattenedList = [];
                const self = this;
                this.componentsTree.forEach(function (item) {
                    flattenedList.push({
                        id: item.id,
                        component_type_id: item.component_type_id,
                        name: item.name,
                        level: 0,
                        params: prepareParams(item, self.measurements)
                    });
                    buildList(item, flattenedList, 1, self.measurements);
                });
                return flattenedList;
            },
        }
    }
</script>

<style scoped>
    .list-item {
        min-height: 40px;
    }
</style>
