<template>
    <vue-tree-list
            @delete-node="deleteComponent"
            :model="tree"
            :default-tree-node-name="$t('js.asset_components.component.default_name')"
            v-bind:default-expanded="true">
    </vue-tree-list>
</template>

<script>
    import {Tree, TreeNode, VueTreeList} from 'vue-tree-list'
    import AxiosService from "../services/axios-service";
    import Utils from "../services/utils"
    import errorHandlingMixin from './mixins/error-handling-mixin'

    export default {
        mixins: [errorHandlingMixin],
        components: {
            VueTreeList
        },
        props: {
            components: Array,
            componentTypes: Array
        },
        data: function () {
            return {
                tree: new Tree([]),
                newTree: {},
                componentTypesMap: {},
                selectedComponent: {},
                allowedComponentTypes: [],
            }
        },
        mounted() {
            // (ak) overrides VueTreeList methods
            VueTreeList.methods.addChild = function () {
                const newUrl = `${Utils.getPath().replace("edit", "components")}/new/?parent=${this.model.real_id}`;
                Turbolinks.visit(newUrl);
            };
            VueTreeList.methods.setEditable = function () {
                const editUrl = this.model.ancestry ? `${Utils.getPath().replace("edit", "components")}/${this.model.real_id}/edit/?parent=${this.model.ancestry}` : `${Utils.getPath().replace("edit", "components")}/${this.model.real_id}/edit`;
                Turbolinks.visit(editUrl);
            };
            this.componentTypes.forEach((type) => {
                this.componentTypesMap[type.id] = type
            });
            this.tree = this.prepareTree(new Tree(this.components));
        },
        methods: {
            configureNode(node) {
                // vue-tree-list uses id field as DOM element id.
                // This prevents from scrolling to node after click
                if (!node.real_id) {
                    node.real_id = node.id;
                    node.id = Utils.generateId()
                }

                const types = this.componentTypesMap;
                node.addLeafNodeDisabled = true;
                node.dragDisabled = true;

                if (node.component_type_id) {
                    const type = types[node.component_type_id];
                    node.addTreeNodeDisabled = !type.allowed_component_types || !type.allowed_component_types.length;
                } else {
                    node.addTreeNodeDisabled = true
                }
            },
            prepareTree(tree) {
                tree.children = tree.children || [];

                tree.children.forEach((child, idx) => {
                    child.order = idx;
                    _dfs(child, this.configureNode)
                });

                function _dfs(node, cb) {
                    cb(node);
                    if (node.children && node.children.length) {
                        for (let child of node.children) {
                            _dfs(child, cb)
                        }
                    }
                }
                return tree
            },
            async deleteComponent(component) {
                if (!confirm(this.$t('js.asset_components.component.remove_confirm'))) return;
                try {
                    let axios = AxiosService.instance();
                    await axios.delete(`${Utils.getPath().replace("edit", "components")}/${component.real_id}`);
                    component.remove();
                    this.$alert.info(this.$t('js.asset_components.component.removed'));
                } catch (err) {
                    this.handleAxiosErrors(err)
                }
            }
        },
    }
</script>

<style>
    .vtl-node-main .vtl-operation {
        display: flex !important;
        margin-left: auto !important;
    }
    .vtl-node-main .vtl-operation > span {
        display: flex;
        align-items: center;
    }
    .vtl-input {
        padding-left: 5px;
    }

    .vtl-icon {
        font-weight: bold !important;
        margin-right: 5px;
        font-size: large;
    }

    .vtl-icon-trash {
        background-color: #dc3545;
        border-color: #dc3545;
    }
    .vtl-icon-trash:hover {
        background-color: #c82333;
        border-color: #bd2130;
        color: #fff !important;
    }
    .vtl-icon-edit {
        background-color: #96855f;
        border-color: #96855f;
    }
    .vtl-icon-edit:hover {
        background-color: #77694b;
        border-color: #716344;
        color: #fff !important;
    }
    .vtl-icon-folder-plus-e {
        background-color: #28a745;
        border-color: #28a745;
    }
    .vtl-icon-folder-plus-e:hover {
        background-color: #218838;
        border-color: #1e7e34;
        color: #fff !important;
    }
    .vtl-icon-folder-plus-e, .vtl-icon-edit, .vtl-icon-trash {
        cursor: pointer;
        color: white;
        padding: 0.25rem;
        border-radius: 0.2rem;
    }
    .vtl-node-content {
        word-break: break-word;
    }
</style>
