export default {
    methods: {
        handleAxiosErrors(err) {
            let resp = err.response;
            console.error('Unexpected error occurred', resp);
            if (!resp.data || !resp.data.status || !resp.data.errors) {
                this.$alert.error(this.$t('js.errors.unexpected_error'));
                return
            }
            for (let fieldErr of Object.values(resp.data.errors)) {
                this.$alert.error(fieldErr)
            }
        },
    }
}