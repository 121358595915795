// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/assets and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("turbolinks").start()

var $ = window.$ = window.jQuery = require('jquery')
var moment = window.moment = require('moment')
var L = window.L = require('leaflet')
var PopperUtils = window.PopperUtils = require('popper.js/dist/popper-utils')
var Popper = window.Popper = require('popper.js/dist/popper')

require("bootstrap")
//require("bootstrap-sass/assets/javascripts/bootstrap")

require("leaflet-geosearch")
require("leaflet-easybutton/src/easy-button")
require("leaflet.markercluster/dist/leaflet.markercluster")

require("tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.min")
require("bootstrap-select/js/bootstrap-select")

require('./vue_init')

require("../src/application.scss")
require("../channels")

