<template>
    <div class="AssetsMap leaflet-touch">
        <div ref="mapid" id="mapid">
            <zone-description :zones="zones" :clicked-zone-id="clickedZoneId" :is-admin="isAdmin"></zone-description>
        </div>
    </div>
</template>

<script>
import L from "leaflet";
import { createMap, createZone, createWagon, createAllStateWagon} from "../mapUtils/map-components";
import ZoneDescription from "./zone-description";

export default {
    components: {
        ZoneDescription, L
    },
    props: {
        wagons: Array,
        zones: Array,
        states: Array,
        lng: {
            type: Number,
            default: 19.480556,
        },
        lat: {
            type: Number,
            default: 52.06916,
        },
        level: {
            type: Number,
            default: 13,
        },
        isAdmin: Boolean,
        namespace: String,
        osmUrl: String,
        ormUrl: String,
        sliderIndex: Number,
        assetInternalId: String
    },
    data () {
        return {
            clickedZoneId: null,
            markers: [],
            map: null
        }
    },
    mounted() {
        this.map = createMap(this.lat,this.lng,this.level,this.osmUrl, this.ormUrl, () => this.handleMapPreclick());
        this.createZones();
        this.createStates();
    },
    methods: {
        handleMapPreclick() {
            if (this.clickedZoneId) {
                this.clickedZoneId=null;
            }
        },
        handleZoneClick(id) {
            this.clickedZoneId = id;
        },
        onCircleClick(id) {
            console.log('Clicked wagon id:', id);
        },
        handleWagonDblclick(assetId) {
            location.href = `/${this.namespace}/assets/${assetId}`;
        },
        handleStateDblclick(assetId,index) {
          location.href = `/${this.namespace}/assets/${assetId}?indexstate=${index}`;
        },
        createStates() {
          const elementsArray = [], elementsStatesArray = [];
          if(!this.states){
            this.wagons.forEach(wagon => {
              if (!wagon.current_asset_state) {
                return;
              }
              const circle = createWagon(wagon, () => this.onCircleClick(wagon.id), () => this.handleWagonDblclick(wagon.id));
              elementsArray.push(circle);
            });

            let elementsGroup = L.markerClusterGroup();
            elementsArray.forEach((obj) => {
              elementsGroup.addLayer(obj);
            })
            this.map.addLayer(elementsGroup);
            const bounds = elementsGroup.getBounds();
            if (bounds.isValid()) {
              this.map.fitBounds(bounds);
            }
          }
          if(this.states){
            this.states.forEach((state,index) => {
              const circle = createAllStateWagon(state, this.assetInternalId, () => this.onCircleClick(state.asset_id), () => this.handleStateDblclick(state.asset_id,(index+1)));
              elementsStatesArray.push(circle);
            })
            let layerGroup = L.layerGroup(elementsStatesArray);

            let marker_index = 0;
            layerGroup.eachLayer((layer) => {
              this.markers[marker_index] = layer;
              this.markers[marker_index].options.oldFillColor = this.markers[marker_index].options.fillColor;
              this.markers[marker_index].options.oldColor = this.markers[marker_index].options.color;
              ++marker_index;
            })

            const elementsStatesGroup = L.featureGroup(elementsStatesArray);
            elementsStatesGroup.addTo(this.map);
            const boundsState = elementsStatesGroup.getBounds();
            if (boundsState.isValid()) {
              this.map.fitBounds(boundsState);
            }
          }
        },
        createZones() {
          if(this.zones) {
            this.zones.forEach(zone => {
              const leafletZone = createZone(zone, () => this.handleZoneClick(zone.id));
              leafletZone.addTo(this.map);
            });
          }
        }
    },
    watch: {
      zones: function () {
        this.createZones();
      },
      states: function () {
        this.createStates();
      },
      sliderIndex: function (newVal) {
        let fg = L.featureGroup();
        if(!!this.markers[newVal]) {
          if(this.markers[newVal]) this.map.removeLayer(this.markers[newVal])
          if(!(this.wagons && this.wagons.length>1)) {
            if(this.markers[newVal]) {
              this.markers[newVal].options.fillColor = "#20fff4";
              this.markers[newVal].options.color = "aqua";
              this.map.addLayer(this.markers[newVal]);
              fg.addLayer(this.markers[newVal]);

              for(let j=0;j<this.markers.length;j++){
                if(j!==newVal && this.markers[j].options.fillColor === "#20fff4"){ //przywraca kolor poprzednio wybranych raportów
                  this.map.removeLayer(this.markers[j])
                  this.markers[j].options.fillColor = this.markers[j].options.oldFillColor;
                  this.markers[j].options.color = this.markers[j].options.oldColor;
                  this.map.addLayer(this.markers[j]);
                  fg.addLayer(this.markers[j]);
                }
              }
            }
          }
        }
      }
    }
}
</script>